<script>
  import Tooltip, { Content, Wrapper } from "@smui/tooltip";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import { CONTEXT_KEY_USER } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const numOfPkgsGroupByStatus = userContext.getNumberOfPackagesGroupByStatus();

  /**
   * 未使用ロール側での作業が未完了の場合にtrue
   * @type {boolean}
   */
  const incomplete =
    (userContext.hasDriverRole() &&
      userContext.getNumberOfInTransitPackages() > 0) ||
    (userContext.hasContractDriverRole() &&
      numOfPkgsGroupByStatus?.未 + numOfPkgsGroupByStatus?.不 > 0);

  /** 宅配業務側での未送信データがある場合にtrue */
  const hasUnsentData = userContext.syncFailureList?.length > 0;
</script>

<div class="roleChangeWrapper">
  <Wrapper rich>
    <button class="roleChangeButton">
      {#if incomplete || (hasUnsentData && userContext.hasContractDriverRole())}
        <span class="roleChangeBadge" />
      {/if}
      <span
        >{$_(`classes.shortRoleName.${userContext.loginUser.roles[0]}`)}</span
      ></button
    >
    {#if incomplete}
      <Tooltip style="top: 40px; left: 0;">
        <Content
          >{userContext.hasDriverRole()
            ? `幹線輸送業務で輸送中の荷物が${userContext.getNumberOfInTransitPackages().toLocaleString()}件残っています`
            : `宅配業務で持出中の荷物が${(numOfPkgsGroupByStatus?.未 + numOfPkgsGroupByStatus?.不).toLocaleString()}件残っています`}
        </Content>
      </Tooltip>
    {:else if hasUnsentData && userContext.hasContractDriverRole()}
      <Tooltip style="top: 40px; left: 0;">
        <Content>宅配業務で送信待ちのデータが残っています</Content>
      </Tooltip>
    {/if}
  </Wrapper>
</div>

<style lang="scss">
  .roleChangeWrapper {
    :global(.mdc-tooltip .mdc-tooltip--rich) {
      width: auto;
      white-space: nowrap;
      overflow: visible;
    }
  }

  .roleChangeBadge {
    position: absolute;
    top: -2px;
    right: -4px;
    padding: 0;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .roleChangeButton {
    position: relative;
    margin-top: 0;
    margin-left: 6px;
    padding: 0;
    width: 32px;
    height: 32px;
    font-size: 12.6px;
    color: #fff;
    background-color: #018786;
    border-radius: 50%;
    border: none;
  }
</style>
