import backendApi from "~/libs/backendApi";

/**
 * ロールを切り替える
 * @param {import("~/libs/commonTypes").UserContext} userContext
 * @param {import("~/libs/constants").DRIVER_ROLE | import("~/libs/constants").CORE_DELIVERY_ROLE} role
 */
export async function switchRole(userContext, role) {
  // ログインAPI呼び出し
  const loginResponse = await backendApi.loginToSwitchRole(
    {
      username: userContext.loginUser.username,
      password: "N/A",
    },
    role,
  );

  // ログイン情報をUserContextに保存
  const currentTime = Date.now();
  userContext.loginUser.roles = loginResponse.roles;
  userContext.loginUser.accessToken = loginResponse.accessToken;
  userContext.loginUser.refreshToken = loginResponse.refreshToken;
  userContext.loginUser.expiresIn = loginResponse.expiresIn;
  userContext.loginUser.refreshExpires =
    loginResponse.refreshExpiresIn > 0
      ? currentTime + loginResponse.refreshExpiresIn * 1000
      : undefined;
  userContext.loginUser.loginTime = currentTime;
}
