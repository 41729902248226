<script>
  import Button, { Label } from "@smui/button";
  import { getContext } from "svelte";

  import sceneImageOutForDelivery from "~/assets/images/sceneOutForDelivery.png";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOutForDelivery from "~/components/help/HelpOutForDelivery.svelte";
  import { initAudioContext } from "~/libs/audio";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    QrHomeTypes,
  } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
  import { updateCenter } from "~/libs/stores";
  import { toast } from "~/libs/toast";
  import DepotSelector from "~/pages/QrHome/DepotSelector.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 選択された配送センター（「ID/名前」形式）[DepotSelectorの変数をbind] */
  let selectedDepot;

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;
  /** @type {import("svelte").ComponentType<HelpOutForDelivery>} */
  let helpContents;

  // ページの初期化処理（同期）
  (() => {
    // 持ち出しの場合かつ昨日以前に持出した荷物がある場合に警告を表示
    if (hasLuggageBeforeYesterday()) {
      toast.error(
        "昨日以前に持ち出し登録をした荷物が配達リストに残っています。" +
          "配達を完了するか、持ち戻り登録を行ってください。",
        { initial: 0, popsWhenPageMoved: true },
      );
    }
  })();

  /**
   * 配達リストに、昨日以前に持出した荷物が含まれている場合にtrueを返す
   * @returns {boolean}
   */
  function hasLuggageBeforeYesterday() {
    const numberOfPackagesGroupByStatus =
      userContext.getNumberOfPackagesGroupByStatus();
    if (
      numberOfPackagesGroupByStatus.未 > 0 ||
      numberOfPackagesGroupByStatus.不 > 0
    ) {
      const today = new Date().setHours(0, 0, 0, 0);
      const deliveryList = userContext.deliveryList;
      for (const delivery of deliveryList) {
        if (delivery.statusText != "済") {
          let outForDeliveryAt = new Date(delivery.outForDeliveryAt).getTime();
          if (outForDeliveryAt < today) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * 荷物のスキャン画面へ遷移する。
   */
  function goToPackageScanPage() {
    initAudioContext();

    updateCenter.set(selectedDepot);

    if (!appContext.firstOutForDeliveryStarted) {
      helpContents = HelpOutForDelivery;
      helpBase = HelpBase;
      appContext.firstOutForDeliveryStarted = true;
    } else {
      pageRouter.moveToOutForDeliveryWithQrCodeScan();
    }

    appContext.store();
    userContext.store();
  }

  function clickHelpConfirm() {
    helpBase = null;
    helpContents = null;
    pageRouter.moveToOutForDeliveryWithQrCodeScan(); // ヘルプ側でAudioContextを初期化
  }
</script>

<p class="img">
  <img src={sceneImageOutForDelivery} alt="業務イメージ" />
</p>

<div class="inputAreaWrapper">
  <div class="inputArea">
    <DepotSelector
      qrHomeType={QrHomeTypes.OUT_FOR_DELIVERY}
      bind:selectedDepot
    />
  </div>
</div>

<Label>
  <Button
    color="secondary"
    variant="unelevated"
    style="width: 300px; min-height: 50px; margin: 40px auto 10px;"
    on:click={goToPackageScanPage}
    disabled={!selectedDepot}
  >
    荷物のスキャンを開始する
  </Button>
</Label>

<div class="subContentsWrapper">
  <!-- ヘルプ表示 -->
  <svelte:component
    this={helpBase}
    {helpContents}
    clickConfirm={clickHelpConfirm}
  />
</div>

<style lang="scss">
</style>
