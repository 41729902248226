<script>
  import { createEventDispatcher, onMount } from "svelte";

  import image1 from "~/assets/images/help/beforeLogin1.png";
  import image2 from "~/assets/images/help/beforeLogin2.png";
  import image3 from "~/assets/images/help/beforeLogin3.png";

  let contentsArea;
  let currentPage = 1;
  let dispatch = createEventDispatcher();

  onMount(() => {
    contentsArea.style.marginLeft = "+200%";
  });

  function previousPage() {
    if (currentPage > 1) {
      contentsArea.style.marginLeft = String((currentPage - 3) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage--;
    }
  }

  function nextPage() {
    if (currentPage < 3) {
      contentsArea.style.marginLeft =
        "-" + String((currentPage - 1) * 200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage++;
    }
  }

  function confirm() {
    dispatch("confirm");
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>
    はじめに<br /><span class="subTitle">ログイン方法とパスワード変更</span>
  </p>
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea" bind:this={contentsArea}>
  <div class="helpContent">
    <img class="helpContentImage" src={image1} alt="helpContent" />
    <div class="messaggeArea">
      <p>
        ユーザーIDとパスワード欄に、<br />
        管理者から連絡を受けた情報を<br />
        入力し、<span class="strong">初期パスワードの変更</span>を<br />
        行ってください。
      </p>
    </div>
  </div>
  <div class="helpContent">
    <img class="helpContentImage2" src={image2} alt="helpContent" />
    <div class="messaggeArea">
      <p>
        ブラウザから強力なパスワードの<br />
        <span class="strong">自動生成</span>が提案される場合は、<br />
        <span class="strong">利用を推奨</span>します。<br />
        提案されない場合は、画面に<br />
        記載の条件に沿ってパスワードを<br />
        設定してください。
      </p>
    </div>
  </div>
  <div class="helpContent">
    <img class="helpContentImage2" src={image3} alt="helpContent" />
    <div class="messaggeArea2">
      <p>
        次回以降スムーズにアプリを利用<br />
        いただくため、変更したパスワードは<br />
        <span class="strong">スマートフォンに記憶</span>させることを<br />
        推奨します。
      </p>
    </div>
    <div class="confirmButtonArea">
      <button class="confirmButton" on:click={confirm}>OK</button>
    </div>
  </div>
</div>
<!-- ページ管理エリア -->
<div class="pageManageArea">
  <div class="pageButtonArea">
    <button class="pageButton" id="previousButton" on:click={previousPage}>
      <span class="dli-chevron-left"></span>
    </button>
  </div>
  <div class="pageStatusArea">
    <div class="pageStatus">
      <div class="page" class:currentPage={currentPage == 1}></div>
      <div class="page" class:currentPage={currentPage == 2}></div>
      <div class="page" class:currentPage={currentPage == 3}></div>
    </div>
  </div>
  <div class="pageButtonArea">
    <button class="pageButton" id="nextButton" on:click={nextPage}>
      <span class="dli-chevron-right"></span>
    </button>
  </div>
</div>

<style>
  /* タイトルエリア */
  .titleArea {
    margin: 32px 0 26px;
    text-align: center;
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
  }
  .subTitle {
    line-height: 35px;
    font-size: 20px;
    font-weight: bold;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: flex;
    width: 300%;
    height: 380px;
  }
  .helpContent {
    width: 33.33%;
    text-align: center;
  }
  .helpContentImage {
    margin: 25px 0;
    width: 220px;
    height: 140px;
  }
  .helpContentImage2 {
    margin: 15px 0;
    width: 200px;
    height: 160px;
  }
  .messaggeArea {
    margin: 0 auto;
    padding-left: 35px;
    width: 100%;
    max-width: 280px;
    text-align: left;
    font-size: 17px;
    line-height: 25px;
  }
  .messaggeArea2 {
    margin: 0 auto 20px;
    padding-left: 20px;
    width: 100%;
    max-width: 300px;
    text-align: left;
    font-size: 17px;
    line-height: 25px;
  }
  .strong {
    background: linear-gradient(transparent 50%, #83d3b8 80%);
  }
  .confirmButtonArea {
    position: relative;
    width: 100%;
    height: 20%;
    text-align: center;
  }
  .confirmButton {
    position: absolute;
    transform: translateX(-50%);
    display: inline-block;
    width: 130px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  /* ページ管理エリア */
  .pageManageArea {
    width: 100%;
    height: 85px;
    display: flex;
  }
  .pageButtonArea {
    position: relative;
    width: 20%;
    vertical-align: auto;
  }
  .pageButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .pageButton :focus {
    background-color: white;
  }
  .dli-chevron-left {
    display: inline-block;
    vertical-align: middle;
    color: #018786;
    line-height: 1;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(25%) rotate(-135deg);
  }
  .dli-chevron-right {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #018786;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(-25%) rotate(45deg);
  }
  .pageStatusArea {
    position: relative;
    width: 60%;
    display: flex;
  }
  .pageStatus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    justify-content: center;
    display: flex;
  }
  .page {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  .currentPage {
    background-color: #018786;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  }
</style>
