import { kanji2number } from "@geolonia/japanese-numeral";
import { config, normalize } from "@scc-kk/normalize-japanese-addresses";
import ky from "ky";

/**
 * @typedef {object} PostcodeAddress
 * @property {string} addr 住所
 * @property {number} [lat] 緯度
 * @property {number} [lng] 経度
 * @property {number} [level] 緯度・経度の精度レベル (2: 市区町村、3: 町丁目、8: 住居表示住所の街区符号・住居番号)
 */

// 住所正規化ライブラリの初期化
if (import.meta.env.VITE_JAPANESE_ADDRESSES) {
  config.japaneseAddressesApi = import.meta.env.VITE_JAPANESE_ADDRESSES;
}

/**
 * 郵便番号と住所の変換用キャッシュ
 * @type {Map<string, Array<PostcodeAddress>>}
 */
const postcodeAddressesCache = new Map();

const addressUtils = {
  /**
   * 都道府県を除いた住所を返します。
   * @param {string} address 住所
   * @returns {string} 都道府県を除いた住所
   */
  trimPrefecture(address) {
    return address.replace(/^.{2,3}[都道府県]/, "");
  },

  /**
   * 2つの住所を半角スペース付きで結合して返す。
   * @param {string} address1
   * @param {string} address2
   * @returns {string}
   */
  joinWithSpace(address1, address2) {
    return address2 ? address1 + " " + address2 : address1;
  },

  /**
   * Geoloniaの住所正規化ライブラリで住所を正規化する。
   * @param {string} address 住所
   * @returns {Promise<import("@scc-kk/normalize-japanese-addresses").NormalizeResult>} 正規化済の住所
   */
  async normalizeAddress(address) {
    return await normalize(address);
  },

  /**
   * Googleマップへのリンク用に住所を正規化する。
   * @param {string} address 住所
   * @param {string} [postcode] 郵便番号
   * @returns {Promise<string>} 正規化済の住所
   */
  async normalizeAddressForGoogleMap(address, postcode) {
    let normalizedAddress = removeDuplicateChomeBanchiGo(
      repairBrokenHyphen(address),
    );
    const parsedAddress = await parseAddress(normalizedAddress, postcode);

    try {
      const nr = await normalize(parsedAddress);
      if (nr.level == 8) {
        let other;
        if (nr.other) {
          other = nr.other.replace(/^(-\d+)(?:-\d+)*\s*号?室?\s*/, "$1 ");
          const firstSpaceIndex = other.indexOf(" ");
          if (firstSpaceIndex != -1) {
            other =
              other.substring(0, firstSpaceIndex + 1) +
              other
                .substring(firstSpaceIndex + 1)
                .replace(/\s*(?:株式|有限|合同|合資|合名)会社.+$/, "")
                .replace(/\s*\d+号?室?$/, "")
                .replace(/\s*\d+[階Ff]$/, "");
          }
          other = other.trim();
        }
        normalizedAddress =
          (nr.pref ?? "") +
          (nr.city ?? "") +
          (nr.town ?? "") +
          (nr.addr ?? "") +
          (other ?? "");
      }
    } catch (error) {
      console.warn(error);
    }
    return normalizedAddress;
  },

  /**
   * 郵便番号に対応する住所を取得する。
   * @param {string} postcode
   * @returns {Promise<Array<PostcodeAddress>>}
   */
  async getAddressesByPostcode(postcode) {
    const postcodeAddresses = postcodeAddressesCache.get(postcode);
    if (!postcodeAddresses) {
      try {
        /** @type {{[key: string]: Array<PostcodeAddress>}} */
        const responseData = await ky
          .get(
            `${import.meta.env.VITE_POSTCODE_ADDRESSES}/${postcode.substring(0, 3)}.json`,
          )
          .json();
        for (const key in responseData) {
          const value = responseData[key];
          postcodeAddressesCache.set(key, value);
        }
        return responseData[postcode];
      } catch (error) {
        console.warn(error);
        // fall through
      }
    }
    return postcodeAddresses;
  },

  /**
   * 住所の丁目・番地・号の部分を`1-2-3`形式で抽出する。
   * 丁目・番地・号のいずれか2つ以上が存在しない場合は正規の住所ではないと判定し、undefinedを返す。
   * @param {string} address
   * @returns {string}
   */
  extractChomeBanchiGou(address) {
    return /(\d+(?:-\d+){1,2})/.exec(replaceChomeBanchiGou(address))?.[1];
  },
};

export default Object.freeze(addressUtils);

/**
 * 丁目、番地、号をハイフンに置き換える
 * @param {string} address
 * @returns {string}
 */
function replaceChomeBanchiGou(address) {
  return address
    .normalize("NFKC")
    .replace(/[−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, "-")
    .replace(
      /(\d+|[〇一二三四五六七八九十])\s*丁目\s*/, //NOSONAR
      (match, chome) => {
        if (chome) {
          return `${kanji2number(chome)}-`;
        } else {
          return match;
        }
      },
    )
    .replace(
      /\s*(\d+|[〇一二三四五六七八九十百千]+)\s*番地?(?:\s*(\d+|[〇一二三四五六七八九十百千]+)\s*号?)?/, //NOSONAR
      (match, banchi, gou) => {
        if (banchi && gou) {
          return `${kanji2number(banchi)}-${kanji2number(gou)}`;
        } else if (banchi) {
          return `${kanji2number(banchi)}`;
        } else {
          return match;
        }
      },
    );
}

/**
 * 丁目・番地・号の区切り文字が文字化けしている場合に、ハイフンに置き換える。
 * @param {string} address
 * @returns {string}
 */
function repairBrokenHyphen(address) {
  // 住所に文字化けっぽい「?」が含まれる場合、「-」に置換する
  const regex = /\d+(?:\?\d+)+/g; //NOSONAR
  const result = regex.exec(address);
  return result
    ? address.substring(0, result.index) +
        address.substring(result.index, regex.lastIndex).replaceAll("?", "-")
    : address;
}

/**
 * 重複した丁目・番地・号を除去する
 * @param {string} address
 * @returns {string}
 */
function removeDuplicateChomeBanchiGo(address) {
  let addressParts = [];
  let found = false;
  for (const part of address.split(" ")) {
    const result = addressUtils.extractChomeBanchiGou(part);
    if (result) {
      if (found) {
        break;
      }
      found = true;
    }
    addressParts.push(part);
  }

  return addressParts.join(" ");
}

/**
 * geoloniaの住所正規化ライブラリを利用して、住所を正規化します
 * https://github.com/geolonia/normalize-japanese-addresses
 * @param {string} address
 * @param {string} postcode
 * @returns {Promise<string>} 住所
 */
async function parseAddress(address, postcode) {
  /** @type {import("@scc-kk/normalize-japanese-addresses").NormalizeResult} */
  let result;

  result = await addressUtils.normalizeAddress(address);

  console.debug(`${address} ${JSON.stringify(result)}`);
  if (result.lat != null && result.lng != null) {
    return address;
  }

  // 郵便番号が指定されている場合は郵便番号をもとに住所の取得を試行
  if (postcode) {
    const postcodeAddresses =
      (await addressUtils.getAddressesByPostcode(postcode)) ?? [];

    // 郵便番号に対応する緯度・経度の精度が8の場合はその住所を返す
    for (const postcodeAddress of postcodeAddresses) {
      if (
        postcodeAddress.level === 8 &&
        typeof postcodeAddress.lat == "number" &&
        typeof postcodeAddress.lng == "number"
      ) {
        console.debug(
          `【〒位置情報A】${address} -> ${JSON.stringify(postcodeAddress)}`,
        );
        return address;
      }
    }

    // 郵便番号に対応する住所と、元の住所の丁・番・号部分を連結して再度照合
    const chomeBanchiGou = extractChomeBanchiGouTatemono(address);
    if (chomeBanchiGou) {
      for (const postcodeAddress of postcodeAddresses) {
        // 郵便番号に対応する住所に含まれる丁・番部分を削除
        const postAddr =
          postcodeAddress.addr.replace(
            /(?:\d+丁目\d+番地|\d+丁目|(?:\d+-)*\d+番地|\d+(?:-\d+)+)$/g, //NOSONAR
            "",
          ) + chomeBanchiGou;
        const result = await addressUtils.normalizeAddress(postAddr);
        if (result.lat != null && result.lng != null) {
          console.debug(
            `【〒位置情報B】${address} -> ${postAddr} ${JSON.stringify(result)}`,
          );
          return postAddr;
        }
      }
    }

    // 郵便番号に対応する緯度・経度が存在する場合はその住所を返す（低精度）
    for (const postcodeAddress of postcodeAddresses) {
      if (
        typeof postcodeAddress.lat == "number" &&
        typeof postcodeAddress.lng == "number"
      ) {
        console.debug(
          `【〒位置情報C】${address} -> ${JSON.stringify(postcodeAddress)}`,
        );
        return postcodeAddress.addr;
      }
    }
  }

  return address;
}

/**
 * 住所の丁目・番地・号とそれに続く部分を抽出する
 * 丁目・番地・号のいずれか2つ以上が存在しない場合は正規の住所ではないと判定し、undefinedを返す。
 * @param {string} address
 * @returns {string}
 */
function extractChomeBanchiGouTatemono(address) {
  const chomeBanchiGo = addressUtils.extractChomeBanchiGou(address);
  if (chomeBanchiGo) {
    const a = replaceChomeBanchiGou(address);
    const startIndex = a.indexOf(chomeBanchiGo);
    if (startIndex > 0) {
      return a.substring(startIndex, a.length);
    }
  }
  return undefined;
}
