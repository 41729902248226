<svg
  id="bulkReceive"
  class="svg-icons-bulk-receive"
  data-name="Layer_2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 328.77 261.53"
>
  <defs>
    <style>
      .bulk-receive-1 {
        fill: #203a72;
      }

      .bulk-receive-2 {
        fill: #14a0de;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer_1">
    <g>
      <g>
        <path
          class="bulk-receive-2"
          d="M70.57,211.79v42.2c0,4.17,3.38,7.55,7.54,7.55h42.2c4.17,0,7.54-3.38,7.54-7.55v-42.2c0-4.17-3.38-7.55-7.54-7.55h-42.2c-4.17,0-7.54,3.38-7.54,7.55ZM87.83,217.25c0-1.03.83-1.86,1.86-1.86h19.05c1.03,0,1.86.83,1.86,1.86v3.69c0,1.03-.83,1.86-1.86,1.86h-19.05c-1.03,0-1.86-.83-1.86-1.86v-3.69Z"
        />
        <path
          class="bulk-receive-2"
          d="M135.74,211.79v42.2c0,4.17,3.38,7.55,7.54,7.55h42.2c4.17,0,7.54-3.38,7.54-7.55v-42.2c0-4.17-3.38-7.55-7.54-7.55h-42.2c-4.17,0-7.54,3.38-7.54,7.55ZM153,217.25c0-1.03.83-1.86,1.86-1.86h19.05c1.03,0,1.86.83,1.86,1.86v3.69c0,1.03-.83,1.86-1.86,1.86h-19.05c-1.03,0-1.86-.83-1.86-1.86v-3.69Z"
        />
        <path
          class="bulk-receive-2"
          d="M152.9,139.08h-42.2c-4.17,0-7.54,3.38-7.54,7.55v42.2c0,4.17,3.38,7.55,7.54,7.55h42.2c4.17,0,7.55-3.38,7.55-7.55v-42.2c0-4.17-3.38-7.55-7.55-7.55ZM143.18,155.77c0,1.03-.84,1.86-1.86,1.86h-19.05c-1.03,0-1.86-.83-1.86-1.86v-3.69c0-1.03.83-1.86,1.86-1.86h19.05c1.03,0,1.86.83,1.86,1.86v3.69Z"
        />
        <path
          class="bulk-receive-2"
          d="M218.07,196.36c4.17,0,7.54-3.38,7.54-7.55v-42.2c0-4.17-3.38-7.55-7.54-7.55h-42.2c-4.17,0-7.54,3.38-7.54,7.55v42.2c0,4.17,3.38,7.55,7.54,7.55h42.2ZM185.58,152.08c0-1.03.83-1.86,1.86-1.86h19.05c1.03,0,1.86.83,1.86,1.86v3.69c0,1.03-.83,1.86-1.86,1.86h-19.05c-1.03,0-1.86-.83-1.86-1.86v-3.69Z"
        />
        <path
          class="bulk-receive-2"
          d="M250.65,204.25h-42.2c-4.17,0-7.54,3.38-7.54,7.55v42.2c0,4.17,3.38,7.55,7.54,7.55h42.2c4.17,0,7.54-3.38,7.54-7.55v-42.2c0-4.17-3.38-7.55-7.54-7.55ZM240.94,220.94c0,1.03-.83,1.86-1.86,1.86h-19.05c-1.03,0-1.86-.83-1.86-1.86v-3.69c0-1.03.83-1.86,1.86-1.86h19.05c1.03,0,1.86.83,1.86,1.86v3.69Z"
        />
      </g>
      <path
        class="bulk-receive-1"
        d="M320.92,53L172.9,1.44c-5.52-1.92-11.52-1.92-17.04,0L7.85,53c-4.7,1.64-7.85,6.07-7.85,11.04v25.82c0,6.46,5.24,11.7,11.7,11.7h13.46v155.38c0,2.54,2.06,4.59,4.59,4.59h18.3c2.54,0,4.59-2.06,4.59-4.59V101.56h223.48v155.38c0,2.54,2.06,4.59,4.59,4.59h18.3c2.54,0,4.59-2.06,4.59-4.59V101.56h13.46c6.46,0,11.7-5.24,11.7-11.7v-25.82c0-4.98-3.15-9.41-7.85-11.04Z"
      />
    </g>
  </g>
</svg>
