<script>
  import Textfield from "@smui/textfield";
  import HelperText from "@smui/textfield/helper-text";
  import autosize from "autosize";

  import { deliveryTarget } from "~/libs/stores";

  /** @type {string} 部屋ごとのナレッジ入力欄にバインドされる内容 */
  export let editableByAddressKnowledge;

  /** @type {object} 変更前の部屋単位ナレッジ(更新有無チェック用) */
  export let changeBeforeByAddressKnowledge;

  /** @type {string} 近隣ごとのナレッジ入力欄にバインドされる内容 */
  export let editableNeighborhoodKnowledge;

  /** @type {object} 変更前の近隣ナレッジ(更新有無チェック用) */
  export let changeBeforeNeighborhoodKnowledge;

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物 */
  const deliveryPackage = $deliveryTarget;

  /** @type {boolean} 既存の部屋単位ナレッジを全て消した時の注記表示フラグ */
  let editableByAddressKnowledgeCaution = false;
  $: if (
    editableByAddressKnowledge === "" &&
    changeBeforeByAddressKnowledge.memo !== ""
  ) {
    editableByAddressKnowledge = changeBeforeByAddressKnowledge.memo;
    editableByAddressKnowledgeCaution = true;
  }

  /** @type {boolean} 近隣に共有するナレッジの入力欄を表示する場合はtrue */
  let openKnowledgeTextareaNeighborhood = false;
  if (deliveryPackage.shippingKnowledge?.neighborhoods) {
    for (const neighborhood of deliveryPackage.shippingKnowledge
      .neighborhoods) {
      if (neighborhood.sameAddress) {
        openKnowledgeTextareaNeighborhood = true;
        break;
      }
    }
  }

  /** @type {boolean} 既存の近隣ナレッジを全て消した時の注記表示フラグ */
  let editableNeighborhoodKnowledgeCaution = false;
  $: if (
    editableNeighborhoodKnowledge === "" &&
    changeBeforeNeighborhoodKnowledge.memo !== ""
  ) {
    editableNeighborhoodKnowledge = changeBeforeNeighborhoodKnowledge.memo;
    editableNeighborhoodKnowledgeCaution = true;
  }

  /**
   * 近隣に共有するナレッジの入力欄を開閉する
   */
  function toggleKnowledgeTextareaNeighborhood() {
    openKnowledgeTextareaNeighborhood = !openKnowledgeTextareaNeighborhood;
  }

  /**
   * Textfield（textarea）に対して自動的な高さ調整を適用する。
   * @param {HTMLElement} textfieldNode
   * @returns {void | import("svelte/action").ActionReturn}
   */
  function applyAutoResizeToTextarea(textfieldNode) {
    const textarea = textfieldNode.querySelector("textarea");
    if (textarea) {
      autosize(textarea);
      return {
        update() {
          autosize["update"](textarea);
        },
        destroy() {
          autosize["destroy"](textarea);
        },
      };
    }
  }
</script>

<div class="knowledgeRegistrationArea">
  <div class="subText">
    <p>同じ住所<sub>(部屋含む)</sub>への配達時に表示されます</p>
    {#if editableByAddressKnowledgeCaution}
      <div class="memoCaution">
        登録済みのメモは削除できません。訂正が必要な場合はその旨追記ください。
      </div>
    {/if}
  </div>
  <div class="margins">
    <Textfield
      textarea
      bind:value={editableByAddressKnowledge}
      input$placeholder="例) 2024/7/1:置き配時は玄関前のOKIPPAを使用するよう指示有"
      use={[applyAutoResizeToTextarea]}
    />
  </div>
  {#if !openKnowledgeTextareaNeighborhood}
    <div class="linkWrapper">
      <button on:click={toggleKnowledgeTextareaNeighborhood}>
        最寄りの駐車位置や建屋に関するメモを残す
      </button>
    </div>
  {:else}
    <div class="subText">
      <p>近隣50m圏内への配達時に表示されます</p>
      {#if editableNeighborhoodKnowledgeCaution}
        <div class="memoCaution">
          登録済みのメモは削除できません。訂正が必要な場合はその旨追記ください。
        </div>
      {/if}
    </div>
    <div class="margins">
      <Textfield
        textarea
        input$placeholder="例) 〇〇アパート前は私道のため駐停車禁止"
        bind:value={editableNeighborhoodKnowledge}
        use={[applyAutoResizeToTextarea]}
      >
        <HelperText slot="helper" persistent
          >駐車位置や集合住宅の注意事項等、配達先の近隣に関するメモはこちらに記入</HelperText
        >
      </Textfield>
    </div>
  {/if}
</div>

<style lang="scss">
  .knowledgeRegistrationArea {
    :global(.mdc-text-field) {
      width: 100%;
    }
    :global(textarea) {
      margin: 10px 0;
      padding: 0 10px;
    }

    .subText {
      font-size: 14px;

      &:nth-of-type(n + 2) {
        margin-top: 4px;
      }
      sub {
        font-size: 12.5px;
      }
      .memoCaution {
        margin-bottom: 2px;
        background-color: #fae6e6;
        padding: 4px 8px;
        border-radius: 5px;
        line-height: 1.4;
        font-size: 13px;
        color: #8d0000;
      }
    }
    .linkWrapper {
      text-align: right;

      button {
        color: #333;
        background-color: #fff;
        border: none;
        text-decoration: underline;
      }
    }
  }
</style>
