<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  import { OfflineException } from "~/libs/backendApi";
  import { CONTEXT_KEY_USER, ShowDepotTypes } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import geolocator from "~/libs/geolocator";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import { calcDistance, formatDistanceInMeters } from "~/libs/mapUtils";
  import pageRouter from "~/libs/pageRouter";
  import { currentPositionStore } from "~/libs/stores";
  import { toast } from "~/libs/toast";

  /**
   * @typedef {{
   *   prefecture: string,
   *   centers: Array<{
   *     id: number,
   *     name: string,
   *     locationShortName: string,
   *     latitude: number,
   *     longitude: number,
   *     type: number,
   *     distance: string,
   *     isNeary: boolean
   *   }>
   * }} ExtraDepotLocation
   */

  /** QrHome画面の種別 @type {import("~/libs/constants").QrHomeTypes} */
  export let qrHomeType;

  /** 選択された配送センター（「ID/名前」形式） */
  export let selectedDepot = "";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 配送センターの一覧 @type {Array<ExtraDepotLocation>} */
  let depotLocationList = [];

  /** 現在位置から最も近い配送センターのoption要素のvalue @type {string} */
  let nearestDepot;
  /** 位置情報が有効か否か */
  let geolocationIsAvailable = false;

  /** 最寄りの配送センターのみを表示するか否か */
  let showsNearbyDepots = true;

  /** @type {import("svelte/store").Unsubscriber} */
  let currentPositionStoreUnsubscriber;

  // ページの初期化処理（非同期）
  loadingProgress.wrapAsync(async () => {
    // 作業場所（配送センター）の取得
    try {
      // qrHomeTypeごとに作業場所として表示するものをbit演算でフィルタ
      depotLocationList = /** @type {Array<ExtraDepotLocation>} */ (
        await depotLocations.get()
      )
        .map((location) => {
          let filtered = location.centers.filter((center) => {
            return center.type & ShowDepotTypes.get(qrHomeType);
          });
          return { prefecture: location.prefecture, centers: filtered };
        })
        .filter((location) => location.centers.length > 0);
    } catch (error) {
      if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.unauthorized"));
        pageRouter.moveToLogin();
        return;
      } else {
        logger.error(
          "[QrHome] 配送センター情報の取得に失敗しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
      // エラーログの出力とToastの表示だけ行って処理は続行
    }

    // 現在地の取得
    geolocator.requestCurrentPosition(false);
    currentPositionStoreUnsubscriber = currentPositionStore.subscribe(
      (position) => {
        if (position) {
          setCoords(position);
        }
      },
    );
  })();

  // 60秒ごとに現在地を取得
  onMount(() => {
    const interval = setInterval(() => {
      geolocator.requestCurrentPosition(false);
    }, 60 * 1000);
    return () => clearInterval(interval);
  });

  onDestroy(() => {
    currentPositionStoreUnsubscriber?.();
  });

  /**
   * @param {GeolocationPosition} position
   */
  function setCoords(position) {
    const sortList = [];

    // 現在位置から配送センターへの距離を算出
    for (let i = 0; i < depotLocationList.length; i++) {
      let centers = depotLocationList[i].centers;
      for (let j = 0; j < centers.length; j++) {
        const centerDistance = Math.round(
          calcDistance(
            position.coords.latitude,
            position.coords.longitude,
            centers[j].latitude,
            centers[j].longitude,
          ),
        );
        centers[j].distance = formatDistanceInMeters(centerDistance, false);
        let item = {
          locationIndex: i,
          centersIndex: j,
          distance: centerDistance,
        };
        sortList.push(item);
      }
    }

    // 現在位置からの距離順にソート
    let result = sortList.sort(function (a, b) {
      return a.distance < b.distance ? -1 : 1;
    });

    // 以前算出した「最寄り」がある場合はクリア
    if (nearestDepot) {
      nearestDepot = undefined;
      depotLocationList.forEach((location) => {
        location.centers.forEach((center) => {
          center.isNeary = false;
        });
      });
    }

    // 最寄りセンターの判定：近い順に最大３個に対して"近い"判定にする
    for (let i = 0; i < Math.min(3, result.length); i++) {
      if (result[i].distance >= 1000) {
        // 現在位置から1000m以上離れている場合は該当件数に関わらず"近い"判定にはしない
        break;
      }
      if (!i) {
        // 最も近いセンターの場合（インデックス0番）
        nearestDepot =
          depotLocationList[result[i].locationIndex].centers[
            result[i].centersIndex
          ].id +
          "/" +
          depotLocationList[result[i].locationIndex].centers[
            result[i].centersIndex
          ].name;
      } else if (result[i].distance - result[0].distance >= 500) {
        // 最も近いセンターより500m以上離れている場合は該当件数に関わらず"近い"判定にはしない
        break;
      }

      depotLocationList[result[i].locationIndex].centers[
        result[i].centersIndex
      ].isNeary = true;
    }

    depotLocationList = depotLocationList; // 距離・最寄りの配送センターをプルダウンに反映
    if (!selectedDepot && nearestDepot) {
      /* センターが未選択かつ最寄りのセンターがある場合 */
      selectedDepot = nearestDepot;
    } else if (nearestDepot && showsNearbyDepots) {
      /* (センターが選択済かつ)最寄りのセンターがある場合かつ最寄りのセンターのみ表示する場合 */
      if (
        !depotLocationList
          .flatMap((e) => e.centers)
          .find((e) => e.id + "/" + e.name === selectedDepot)?.isNeary
      ) {
        // 選択中のセンターが最寄りに該当しない場合は選択を解除
        selectedDepot = "";
      }
    }
    geolocationIsAvailable = true;
  }
</script>

<div class="inputGroup">
  <p class="groupName">作業場所 (配送センター)</p>
  <div class="groupContent inputCenterArea">
    {#if depotLocationList != null}
      <label class="inputCenterLabel">
        <select
          name="inputCenter"
          class="selectInput"
          id="inputCenter"
          bind:value={selectedDepot}
        >
          <option value="" disabled selected={!selectedDepot}
            >選択してください</option
          >
          {#each depotLocationList as { prefecture, centers }}
            {#if showsNearbyDepots && nearestDepot}
              {#each centers as { id, name, distance, isNeary }}
                {#if isNeary}
                  <option
                    value={id + "/" + name}
                    selected={id + "/" + name === selectedDepot}
                    >{name}{#if distance}{" (" + distance + ")"}{/if}</option
                  >
                {/if}
              {/each}
            {:else}
              <optgroup label={prefecture}>
                {#each centers as { id, name, distance }}
                  <option
                    value={id + "/" + name}
                    selected={id + "/" + name === selectedDepot}
                    >{name}{#if distance}{" (" + distance + ")"}{/if}</option
                  >
                {/each}
              </optgroup>
            {/if}
          {/each}
        </select>
      </label>
      {#if nearestDepot}
        <FormField>
          <Checkbox
            bind:checked={showsNearbyDepots}
            on:change={(event) => {
              if (/** @type {HTMLInputElement} */ (event.target).checked) {
                if (
                  !depotLocationList
                    .flatMap((e) => e.centers)
                    .find((e) => e.id + "/" + e.name === selectedDepot)?.isNeary
                ) {
                  // 最寄りの配送センターに絞り込んだ際に元々選択していた配送センターが非表示になる場合
                  selectedDepot = "";
                }
              }
            }}
          />
          <span slot="label">最寄りの配送センターのみ表示</span>
        </FormField>
      {/if}
      {#if geolocationIsAvailable && (!nearestDepot || (selectedDepot && selectedDepot != nearestDepot))}
        <div style="display: flex;">
          <p class="notes" style="width: 15px;">※</p>
          <p class="notes">
            {#if !nearestDepot}
              最寄りの配送センターがありません
            {:else}
              最も近い配送センター以外が選択されています
            {/if}
          </p>
        </div>
      {/if}
    {:else}
      <select name="inputCenter" class="selectInput" id="inputCenter" disabled>
        <option selected>取得失敗</option>
      </select>
    {/if}
  </div>
</div>

<style lang="scss">
  .inputCenterArea {
    .inputCenterLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        right: 15px;
        width: 10px;
        height: 7px;
        background-color: #535353;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: "";
        pointer-events: none;
      }

      select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        padding: 0.4em 30px 0.4em 0.4em;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
        overflow-x: hidden;
      }
    }

    .selectInput {
      height: 40px;
    }

    .notes {
      width: 100%;
      text-align: left;
      line-height: 15px;
      margin-top: 3px;
      font-size: 12px;
      color: red;
    }
  }
</style>
