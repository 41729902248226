<script>
  import Button, { Label } from "@smui/button";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import sceneImageReturnToEc from "~/assets/images/sceneReturnToEc.png";
  import { initAudioContext } from "~/libs/audio";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    QrHomeTypes,
    STATUS_RETURNED,
    STATUS_RETURNING,
  } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
  import { updateCenter } from "~/libs/stores";
  import DepotSelector from "~/pages/QrHome/DepotSelector.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 選択された配送センター（「ID/名前」形式）[DepotSelectorの変数をbind] */
  let selectedDepot;

  /** @type {Array<number>} */
  let returnWorkList = [STATUS_RETURNING, STATUS_RETURNED];

  /** @type {import("~/libs/constants").STATUS_RETURNING | import("~/libs/constants").STATUS_RETURNED}*/
  let selectReturnWork;

  /**
   * 荷物のスキャン画面へ遷移する。
   */
  function goToPackageScanPage() {
    initAudioContext();

    if (selectReturnWork === STATUS_RETURNED) {
      updateCenter.set(null);
    } else {
      updateCenter.set(selectedDepot);
    }

    pageRouter.moveToReturnToEcWithQrCodeScan(selectReturnWork);

    appContext.store();
    userContext.store();
  }
</script>

<p class="img">
  <img src={sceneImageReturnToEc} alt="業務イメージ" />
</p>

<div class="inputAreaWrapper">
  <div class="inputArea">
    <div class="inputGroup">
      <p class="groupName">作業内容</p>
      <div class="groupContent inputReturnWorkArea">
        <label class="inputReturnWorkLabel">
          <select
            name="inputReturnWork"
            class="selectInput"
            id="inputReturnWork"
            bind:value={selectReturnWork}
          >
            <option value="" selected disabled>選択してください</option>
            {#each returnWorkList as status}
              <option value={status}
                >{$_(`classes.returnWork.${status}`)}</option
              >
            {/each}
          </select>
        </label>
      </div>
    </div>
    {#if selectReturnWork === STATUS_RETURNING}
      <DepotSelector qrHomeType={QrHomeTypes.RETURN_TO_EC} bind:selectedDepot />
    {/if}
  </div>
</div>

<Label>
  <Button
    color="secondary"
    variant="unelevated"
    style="width: 300px; min-height: 50px; margin: 40px auto 10px;"
    on:click={goToPackageScanPage}
    disabled={(!selectedDepot && selectReturnWork !== STATUS_RETURNED) ||
      !Number.isInteger(selectReturnWork)}
  >
    荷物のスキャンを開始する
  </Button>
</Label>

<style lang="scss">
  .inputReturnWorkArea {
    .inputReturnWorkLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        right: 15px;
        width: 10px;
        height: 7px;
        background-color: #535353;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        content: "";
        pointer-events: none;
      }

      select {
        appearance: none;
        width: 100%;
        height: 2.8em;
        padding: 0.4em 30px 0.4em 0.4em;
        border: 1px solid #cccccc;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }

      .selectInput {
        height: 40px;
      }
    }
  }
</style>
