import geolocator from "~/libs/geolocator";
import { openedPushInfo, receivedPushInfo } from "~/libs/stores";

/** iOSネイティブアプリの最低要求バージョン */
export const MINIMUM_IOS_NATIVE_APP_VERSION = "1.1.0";

/**
 * initializeWKWebView()で初期化した際のmessageHandlers。
 * ※iOS 18以上はセキュリティコンテキストの関係で、iframeやGoogleマップなどの別アプリを開いた際にmessageHandlersにundefinedが設定されることへの対策
 * @type {typeof window.webkit.messageHandlers}
 */
let messageHandlersAtInit;

/**
 * 現在のiOSネイティブアプリのバージョン
 * @type {string}
 */
let currentVersion;

const iosNativeApp = {
  /**
   * @returns {typeof window.webkit.messageHandlers}
   */
  get messageHandlers() {
    return window.webkit?.messageHandlers ?? messageHandlersAtInit;
  },

  /**
   * iOSネイティブアプリのWKWebView上で動作している場合向けの初期化処理を実施する。
   */
  initializeWKWebView() {
    messageHandlersAtInit = window.webkit?.messageHandlers;
    if (iosNativeApp.messageHandlers) {
      window.webkitCallbackHandlers = {
        geolocatorSuccessCallback: geolocator.successCallback,
        geolocatorErrorCallback: geolocator.errorCallback,
        remotePushReceiveCallback: this.remotePushReceiveCallback,
        remotePushOpenCallback: this.remotePushOpenCallback,
      };

      iosNativeApp.messageHandlers?.requireMinimumVersion
        ?.postMessage?.(MINIMUM_IOS_NATIVE_APP_VERSION)
        .then((version) => {
          console.log("現在のiOSネイティブアプリのバージョン:", version);
          currentVersion = version;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  /**
   * 現在のiOSネイティブアプリのバージョンをN.N.N形式の文字列で返す。
   * 取得できない場合やiOSネイティブアプリ上で動作していない場合は空文字列を返す。
   * @returns {string}
   */
  getCurrentVersion() {
    return currentVersion ?? "";
  },

  /**
   * iOSと連携して認証情報を保存する。
   * @param {string} username
   * @param {string} password
   */
  saveCredentials(username, password) {
    iosNativeApp.messageHandlers?.saveCredentials?.postMessage?.({
      username: username,
      password: password,
    });
  },

  /**
   * 位置情報を要求する。
   * 位置情報は以下のコールバック関数を通じて受け取る。
   * - window.webkitCallbackHandlers.geolocatorSuccessCallback
   * - window.webkitCallbackHandlers.geolocatorErrorCallback
   *
   * @param {boolean} requiresHighAccuracy 高精度の位置情報が必要か否か
   */
  requestLocation(requiresHighAccuracy) {
    iosNativeApp.messageHandlers?.requestLocation?.postMessage?.(
      requiresHighAccuracy,
    );
  },

  /**
   * BundleIdとDeviceTokenを取得する。
   * @returns {Promise<{ bundleId: string, deviceToken: string } | null>}
   */
  async getDeviceToken() {
    if (iosNativeApp.messageHandlers) {
      const deviceToken = await iosNativeApp.messageHandlers?.getDeviceToken
        ?.postMessage?.({})
        .then((deviceToken) => {
          console.log("deviceToken", deviceToken);
          return JSON.parse(deviceToken);
        })
        .catch((error) => {
          console.error(error);
          return null;
        });

      return deviceToken;
    }
  },

  /**
   * ネイティブアプリのOSログを取得する。
   * @returns {Promise<string | null>}
   */
  async getOsLog() {
    if (iosNativeApp.messageHandlers) {
      const osLog = await iosNativeApp.messageHandlers?.getOsLog
        ?.postMessage?.({})
        .then((osLog) => {
          console.log("osLog", osLog);
          return osLog;
        })
        .catch((error) => {
          console.error(error); // use non-logger explicitly
          return null;
        });

      console.log("osLog", osLog);
      return osLog;
    }
  },

  /**
   * プッシュ通知を受信した際に呼ばれるコールバック関数。
   * @param {import("~/libs/commonTypes").PushNotificationInfo} receivedInfo
   */
  remotePushReceiveCallback(receivedInfo) {
    console.log("remotePushReceiveCallback", receivedInfo);
    receivedPushInfo.set(receivedInfo);
  },

  /**
   * プッシュ通知を開封した際に呼ばれるコールバック関数。
   * @param {import("~/libs/commonTypes").PushNotificationInfo} receivedInfo
   */
  remotePushOpenCallback(receivedInfo) {
    console.log("remotePushOpenCallback", receivedInfo);
    openedPushInfo.set(receivedInfo);
  },
};

export default Object.freeze(iosNativeApp);
