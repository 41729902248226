<svg
  id="takeOver"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 260.31 228.01"
  class="svg-icons-take-over"
  ><defs
    ><style>
      .take-over-cls-1 {
        fill: #14a0de;
      }
      .take-over-cls-1,
      .take-over-cls-2 {
        stroke-width: 0px;
      }
      .take-over-cls-2 {
        fill: #203a72;
      }
    </style></defs
  ><g id="Layer_1"
    ><path
      class="take-over-cls-2"
      d="M89.46,83.82h.55c11.55,0,21.08,9.23,21.39,20.84h14.67c4.28,0,7.7-3.42,7.7-7.64v-46.99c0-4.22-3.42-7.7-7.7-7.7h-46.99c-4.22,0-7.64,3.48-7.64,7.7v30.31c.12.18.18.31.31.43,2.93,1.83,12.1,3.24,17.72,3.06Z"
    /><circle
      class="take-over-cls-2"
      cx="36.09"
      cy="24.45"
      r="24.45"
      transform="translate(-6.72 32.68) rotate(-45)"
    /><path
      class="take-over-cls-2"
      d="M90.28,116.23c6.07-.16,10.87-5.21,10.71-11.28-.16-6.07-5.39-10.83-11.28-10.71-8.39.19-22.44-2.04-25.91-6.7-4.1-5.51-7.07-12.29-8.83-17.02-3.47-9.28-12.12-15.56-22.04-16-6.7-.28-13.24,2.12-18.16,6.81-4.85,4.63-7.64,11.12-7.66,17.83l-1.1,54.08c-.03.77.01,1.54.13,2.3l5.62,37.38L.47,212.43c-1.86,6.49,1.9,13.25,8.39,15.11,1.12.32,2.25.47,3.37.47,5.32,0,10.21-3.5,11.74-8.86l12.03-42.06c.48-1.68.6-3.45.33-5.18l-4.75-31.59,17.18,33.98,3.46,42.48c.55,6.73,6.47,11.72,13.17,11.19,6.73-.55,11.74-6.44,11.19-13.17l-3.66-44.87c-.13-1.58-.56-3.11-1.27-4.52l-17.51-34.65-.18-22.93c13.11,8.46,31.96,8.51,36.32,8.4Z"
    /><circle
      class="take-over-cls-2"
      cx="224.22"
      cy="24.45"
      r="24.45"
      transform="translate(48.38 165.71) rotate(-45)"
    /><path
      class="take-over-cls-2"
      d="M259.84,212.43l-11.3-39.51,5.62-37.38c.12-.76.16-1.53.13-2.3l-1.1-54.08c-.01-6.7-2.8-13.2-7.65-17.82-4.84-4.62-11.49-7.1-18.16-6.81-9.92.44-18.57,6.72-22.04,16-1.77,4.73-4.74,11.52-8.83,17.02-3.48,4.67-17.21,6.88-25.91,6.7-6.18-.21-11.12,4.64-11.28,10.71-.16,6.07,4.64,11.12,10.71,11.28,4.35.12,23.2.07,36.32-8.4l-.18,22.93-17.51,34.65c-.71,1.41-1.14,2.94-1.27,4.52l-3.66,44.87c-.55,6.73,4.46,12.63,11.19,13.17,6.73.52,12.63-4.46,13.17-11.19l3.46-42.48,17.17-33.98-4.75,31.59c-.26,1.73-.15,3.5.33,5.18l12.03,42.06c1.53,5.37,6.43,8.86,11.74,8.86,1.11,0,2.24-.15,3.37-.47,6.49-1.86,10.25-8.62,8.39-15.11Z"
    /><path
      class="take-over-cls-1"
      d="M150.51,128.45c-2.39-2.38-6.26-2.39-8.64,0-2.39,2.39-2.39,6.26,0,8.64l7.79,7.78h-53.97c-3.37,0-6.11,2.74-6.11,6.12s2.74,6.11,6.11,6.11h53.97s-7.79,7.78-7.79,7.78c-2.39,2.39-2.39,6.26,0,8.64,1.19,1.19,2.76,1.79,4.32,1.79s3.13-.6,4.32-1.79l18.22-18.22c2.39-2.39,2.39-6.26,0-8.64l-18.22-18.22Z"
    /></g
  ></svg
>
