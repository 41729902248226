<script>
  import { createEventDispatcher } from "svelte";

  import image1 from "~/assets/images/help/nonDelivery1.png";

  let contentsArea;
  let dispatch = createEventDispatcher();

  function confirm() {
    dispatch("confirm");
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>配達不可登録の流れ</p>
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea" bind:this={contentsArea}>
  <div class="helpContent">
    <img class="helpContentImage" src={image1} alt="helpContent" />
    <div class="confirmButtonArea">
      <button class="confirmButton" on:click={confirm}>OK</button>
    </div>
  </div>
</div>

<style>
  /* タイトルエリア */
  .titleArea {
    margin: 35px 0 20px;
    text-align: center;
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: flex;
    height: 495px;
  }
  .helpContent {
    text-align: center;
  }
  .helpContentImage {
    width: 310px;
    height: 400px;
    border-radius: 10px;
  }
  .confirmButtonArea {
    position: relative;
    width: 100%;
    height: 20%;
    text-align: center;
  }
  .confirmButton {
    position: absolute;
    transform: translateX(-50%);
    display: inline-block;
    width: 130px;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 65px;
  }
</style>
