<script>
  import Button, { Label } from "@smui/button";
  import Chip, { Set, Text, TrailingIcon } from "@smui/chips";
  import FormField from "@smui/form-field";
  import Radio from "@smui/radio";
  import Tab from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import { HTTPError } from "ky";
  import { getContext, onDestroy, tick } from "svelte";
  import { _ } from "svelte-i18n";

  import sceneImagePickupAndSort from "~/assets/images/scenePickupAndSort.png";
  import sceneImageUnload from "~/assets/images/sceneUnloadOnDestinationCenter.png";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import FinishWorkDialog from "~/components/FinishWorkDialog.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpSelectMethod from "~/components/help/HelpSelectMethod.svelte";
  import HelpTakeOverFromManager from "~/components/help/HelpTakeOverFromManager.svelte";
  import HelpUnloading from "~/components/help/HelpUnloading.svelte";
  import { initAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    LastOperationTypes,
    NotificationCategory,
    QrHomeTypes,
    STATUS_HELD_IN_DEPOT,
    inTransitWorkTypes,
  } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import geolocator from "~/libs/geolocator";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { lastOperationType, updateCenter } from "~/libs/stores";
  import { reserveUpdateDeliveryRecordsAndSyncBackend } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import { formatTrackingNumber } from "~/libs/trackingNumberUtils";
  import DepotSelector from "~/pages/QrHome/DepotSelector.svelte";
  import AcceptTakeoverDialog from "~/pages/QrHome/QrHomePickupAndUnload/AcceptTakeoverDialog.svelte";
  import RequestTakeoverDialog from "~/pages/QrHome/QrHomePickupAndUnload/RequestTakeoverDialog.svelte";
  import { getNumberOfInTransitPackagesByIndex } from "~/pages/QrHome/QrHomePickupAndUnload/utils";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const lastOperationTypeUnsubscriber = lastOperationType.subscribe(
    async (value) => {
      if (value != undefined) {
        await tick();
        messageType = value;
        lastOperationType.set(undefined);
        finishWorkDialog.openDialog();
      }
    },
  );

  /** 荷受け・荷下ろし用のタブ定義 */
  const tabs = {
    荷受け: "荷受け",
    荷下ろし: "荷下ろし",
  };
  /** アクティブな荷受け・荷下ろし用のタブ */
  let activeTab = userContext.inTransitDeliveryList?.length
    ? tabs.荷下ろし
    : tabs.荷受け;

  /**
   * センターIDをキーとしたセンター情報のMap
   * @type {Map<number, import("~/libs/commonTypes").DepotLocation>}
   */
  let centersMap;

  /** 選択された配送センター（「ID/名前」形式）[DepotSelectorの変数をbind] */
  let selectedDepot;

  /** 荷受時の作業内容 @type {number} */
  let selectInTransitWork;

  /** 輸送中荷物の情報 @type {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} */
  let inTransitDeliveryList = [];

  /**
   * 荷下ろし対象の情報
   * @type {{
   *   transportDestinationId: number,
   *   numberOfBasketCar: number,
   * }}
   */
  let unloadingTargetInfo = {
    transportDestinationId: null,
    numberOfBasketCar: 0,
  };

  /** 輸送中の荷物の引継受入ダイアログ @type {AcceptTakeoverDialog} */
  let acceptTakeoverDialog;

  /** 輸送中の荷物の引継要求ダイアログ @type {RequestTakeoverDialog} */
  let requestTakeoverDialog;

  /** 荷下ろし登録確認ダイアログ @type {ConfirmDialog} */
  let unloadingConfirmDialog;

  /** 荷下ろしモード @type {boolean} */
  let unloadingMode = false;

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;

  /** @type {import("svelte").ComponentType<HelpSelectMethod>} */
  let helpContents;

  let heightOfWorkTab;
  let heightOfPickupContents;
  let heightOfUnloadContents;
  let contentsWrapper;
  let tabContentsArea;
  let pickupContents;
  let unloadContents;

  /** @type {import("~/libs/constants").LastOperationTypes} */
  let messageType;

  /**
   * ダイアログコンポーネント（業務終了登録用）のインスタンス
   * @type {FinishWorkDialog}
   */
  let finishWorkDialog;

  // ページの初期化処理（非同期）
  loadingProgress.wrapAsync(async () => {
    // 配送センターリストを取得
    try {
      centersMap = await depotLocations.getCentersMap();
    } catch (error) {
      if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else if (error instanceof HTTPError && error.response?.status == 401) {
        toast.error($_("errors.unauthorized"));
        pageRouter.moveToLogin();
        return;
      } else {
        logger.error(
          "[QrHomePickupAndUnload] 配送センター情報の取得に失敗しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }

    if (userContext.inTransitDeliveryList?.length) {
      // 輸送中荷物がある場合
      inTransitDeliveryList = userContext.inTransitDeliveryList;

      // 全ての荷下ろし中チェックを解除
      for (let i = 0; i < inTransitDeliveryList.length; i++) {
        for (
          let j = 0;
          j < inTransitDeliveryList[i].deliveryInfoList.length;
          j++
        ) {
          for (
            let k = 0;
            k <
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
            k++
          ) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c =
              false;
          }
        }
      }
    }

    // 初めての画面表示時にヘルプを表示
    if (!appContext.firstQrHomePickupOpened) {
      helpContents = HelpSelectMethod;
      helpBase = HelpBase;
      appContext.firstQrHomePickupOpened = true;
      appContext.store();
    }
  })();

  /**
   * 指定された輸送先センターへの輸送中カゴ車の総数を返す。
   * @param {number} transportDestinationId
   * @returns {number}
   */
  function getNumberOfInTransitBasketCars(transportDestinationId) {
    let count = 0;
    for (const inTransitDeliveryInfo of inTransitDeliveryList) {
      for (const deliveryInfo of inTransitDeliveryInfo.deliveryInfoList) {
        if (deliveryInfo.transportDestinationId === transportDestinationId) {
          count += deliveryInfo.basketCarList.length;
        }
      }
    }
    return count;
  }

  /**
   * 荷下ろし対象としてチェック済みのカゴ車と荷物の数を返す。
   * @returns {{
   *   packagesCount: number,
   *   basketCarsCount: number,
   * }}
   */
  function getNumberOfUnloadingPackages() {
    let packagesCount = 0;
    let basketCarsCount = 0;
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            basketCarsCount++;
            for (
              let l = 0;
              l <
              inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].v
                .length;
              l++
            ) {
              packagesCount +=
                inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].v[
                  l
                ].quantity;
            }
          }
        }
      }
    }
    return { packagesCount, basketCarsCount };
  }

  /**
   * 荷下ろし登録を行い、Contextの輸送中荷物情報を更新する
   */
  async function updateStatuses() {
    try {
      // 現在時刻を保持
      const requestedTimeStamp = Date.now();
      const data = await execStatusUpdateApi(requestedTimeStamp);
      if (data && data.updateFailed) {
        const updateFailedNumbers = data.updateFailed;
        const outputTrackingNumbers = updateFailedNumbers
          .map((t) => formatTrackingNumber(t))
          .join(", ");
        toast.error(
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          $_("errors.unableUnloading", {
            values: { trackingNumber: outputTrackingNumbers },
          }),
        );
      }
      // Contextの輸送中荷物情報を更新
      await updateInTransitDeliveryList(requestedTimeStamp);

      if (!(await checkFinishWork())) {
        toast.info($_("message.updateCompleteUnloading"));
      }
    } catch (e) {
      logger.error(
        "[QrHome] 荷下ろし登録でエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          inTransitDeliveryList: inTransitDeliveryList,
        },
        e,
      );
      if (e instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 荷下ろしした荷物を「保管中」ステータスに更新する。
   * @param {number} requestedTimeStamp リクエスト時刻
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async function execStatusUpdateApi(requestedTimeStamp) {
    let body = new FormData();
    let statusUpdateEvent = { response: true };
    let events = [];
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[
              k
            ].v.forEach((trackingNumberAndQuantity) => {
              let event = {
                trackingNumber: trackingNumberAndQuantity.trackingNumber,
                status: STATUS_HELD_IN_DEPOT,
                locationId:
                  inTransitDeliveryList[i].deliveryInfoList[j]
                    .transportDestinationId,
              };
              events.push(event);
            });
          }
        }
      }
    }
    statusUpdateEvent["events"] = events;
    let blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });
    body.append("status-update-event", blob2);

    return backendApi.updateShipmentStatus(body, requestedTimeStamp);
  }

  /**
   * 荷下ろし登録後の輸送中荷物情報を更新する
   * @param {number} requestedTimeStamp
   */
  async function updateInTransitDeliveryList(requestedTimeStamp) {
    // タップされたカゴ車情報を削除
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.splice(
              k,
              1,
            );
            k--;
          }
        }
        if (
          inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length ===
          0
        ) {
          inTransitDeliveryList[i].deliveryInfoList.splice(j, 1);
          j--;
        }
      }
      if (inTransitDeliveryList[i].deliveryInfoList.length === 0) {
        inTransitDeliveryList.splice(i, 1);
        i--;
      }
    }

    if (inTransitDeliveryList.length === 0) {
      // 輸送中荷物がなくなった場合
      activeTab = tabs.荷受け;
    }

    inTransitDeliveryList = inTransitDeliveryList;
    userContext.inTransitDeliveryList = inTransitDeliveryList;
    userContext.store();
    unloadingMode = false;

    // ドライバーの稼働状況を非同期でBEに同期
    reserveUpdateDeliveryRecordsAndSyncBackend(userContext, requestedTimeStamp);
  }

  /**
   * 荷下ろし対象が選択されていない場合に荷下ろしモードを解除する。
   */
  function checkUnloadingModeRelease() {
    for (let i = 0; i < inTransitDeliveryList.length; i++) {
      for (
        let j = 0;
        j < inTransitDeliveryList[i].deliveryInfoList.length;
        j++
      ) {
        for (
          let k = 0;
          k < inTransitDeliveryList[i].deliveryInfoList[j].basketCarList.length;
          k++
        ) {
          if (inTransitDeliveryList[i].deliveryInfoList[j].basketCarList[k].c) {
            return;
          }
        }
      }
    }
    unloadingMode = false;
    unloadingTargetInfo = {
      transportDestinationId: null,
      numberOfBasketCar: 0,
    };
  }

  /**
   * 輸送元センター単位のリストに、荷下ろし対象の輸送先センターが含まれているかを返す。
   * @param {object} deliveryInfoList
   * @returns {boolean}
   */
  function includeTargetDestination(deliveryInfoList) {
    return deliveryInfoList.some(
      (deliveryInfo) =>
        deliveryInfo.transportDestinationId ===
        unloadingTargetInfo.transportDestinationId,
    );
  }

  /**
   * 輸送中荷物が無くなった場合に、業務を終了してtrueを返す
   * @returns {Promise<boolean>}
   */
  async function checkFinishWork() {
    if (userContext.getNumberOfInTransitPackages() === 0) {
      // すべての荷下ろしが完了した場合
      lastOperationType.set(LastOperationTypes.ALL_UNLOAD);
      finishWorkDialog.openDialog();
      /*
      // 業務終了登録
      await workManage.finish(userContext, DriverType.CORE_DELIVERY);
      if (userContext.duringDriverWorking) {
        // 宅配業務中であれば宅配業務に切替え
        await switchRole(userContext, DRIVER_ROLE);
        toast.info($_("message.changeRoleToDriver"));
        pageRouter.moveToList();
      } else {
        // それ以外は業務開始画面に遷移
        toast.info($_("message.updateCompleteAllUnloading"));
        pageRouter.moveToBeginWork();
      }
      */
      return true;
    } else {
      return false;
    }
  }

  /**
   * 一括荷受け画面へ遷移する。
   */
  function goToBulkReceivePage() {
    updateCenter.set(selectedDepot);
    pageRouter.moveToBulkReceive();
  }

  /**
   * 荷物のスキャン画面へ遷移する。
   */
  function goToPackageScanPage() {
    initAudioContext();

    updateCenter.set(selectedDepot);

    pageRouter.moveToPickupAndSortWithQrCodeScan();

    appContext.store();
    userContext.store();
  }

  /**
   * ヘルプを閉じる
   */
  function clickConfirm() {
    helpBase = null;
    helpContents = null;
  }

  /**
   * activeタブが初めて荷下ろしを選択した場合、ヘルプを表示する
   */
  $: if (activeTab === tabs.荷下ろし && !appContext.firstQrHomeUnloadOpened) {
    helpContents = HelpUnloading;
    helpBase = HelpBase;
    appContext.firstQrHomeUnloadOpened = true;
    appContext.store();
  }

  /** activeTab, heightOfPickupContents, heightOfUnloadContentsの変更に伴うリアクティブ処理 */
  $: {
    adjustHeightOfTabContents(
      // @ts-ignore
      activeTab,
      heightOfPickupContents,
      heightOfUnloadContents,
    );
  }

  /**
   * activeTabが変更された場合に呼び出されるリアクティブハンドラー
   */
  function adjustHeightOfTabContents() {
    if (!tabContentsArea || !contentsWrapper) {
      return;
    }

    const heightOfMain = document.getElementsByTagName("main")[0].offsetHeight;
    const tabMargin = 20;

    if (activeTab === tabs.荷受け) {
      tabContentsArea.style.height = heightOfPickupContents + "px";

      if (heightOfPickupContents >= heightOfUnloadContents) {
        contentsWrapper.style.top = 0 + "px";
      } else {
        // 荷下ろしの場合のcontentsWrapperのmainエリアに対する相対位置
        let topOfContentsWrapperWhenUnload =
          heightOfUnloadContents + heightOfWorkTab + tabMargin >= heightOfMain
            ? 0
            : (heightOfMain -
                (heightOfUnloadContents + heightOfWorkTab + tabMargin)) /
              2;

        // 荷受けの場合のcontentsWrapperのmainエリアに対する相対位置(調整前)
        let topOfContentsWrapperWhenPickup =
          heightOfPickupContents + heightOfWorkTab + tabMargin >= heightOfMain
            ? 0
            : (heightOfMain -
                (heightOfPickupContents + heightOfWorkTab + tabMargin)) /
              2;

        if (topOfContentsWrapperWhenUnload <= 0) {
          contentsWrapper.style.top = -topOfContentsWrapperWhenPickup + "px";
        } else {
          contentsWrapper.style.top =
            -(topOfContentsWrapperWhenPickup - topOfContentsWrapperWhenUnload) +
            "px";
        }
      }
    } else {
      tabContentsArea.style.height = heightOfUnloadContents + "px";

      if (heightOfPickupContents <= heightOfUnloadContents) {
        contentsWrapper.style.top = 0 + "px";
      } else {
        // 荷受けの場合のcontentsWrapperのmainエリアに対する相対位置
        let topOfContentsWrapperWhenPickup =
          heightOfPickupContents + heightOfWorkTab + tabMargin >= heightOfMain
            ? 0
            : (heightOfMain -
                (heightOfPickupContents + heightOfWorkTab + tabMargin)) /
              2;

        // 荷下ろしの場合のcontentsWrapperのmainエリアに対する相対位置(調整前)
        let topOfContentsWrapperWhenUnload =
          heightOfUnloadContents + heightOfWorkTab + tabMargin >= heightOfMain
            ? 0
            : (heightOfMain -
                (heightOfUnloadContents + heightOfWorkTab + tabMargin)) /
              2;

        if (topOfContentsWrapperWhenPickup <= 0) {
          contentsWrapper.style.top = -topOfContentsWrapperWhenUnload + "px";
        } else {
          contentsWrapper.style.top =
            -(topOfContentsWrapperWhenUnload - topOfContentsWrapperWhenPickup) +
            "px";
        }
      }
    }
  }

  onDestroy(() => {
    lastOperationTypeUnsubscriber();
  });
</script>

<!-- ヘルプ表示 -->
<svelte:component this={helpBase} {helpContents} {clickConfirm} />
<div class="contentsWrapper" bind:this={contentsWrapper}>
  {#if inTransitDeliveryList?.length}
    <div class="workTabs" bind:offsetHeight={heightOfWorkTab}>
      <TabBar tabs={Object.values(tabs)} let:tab bind:active={activeTab}>
        <Tab {tab} ripple={false}>
          <Label>{tab}</Label>
        </Tab>
      </TabBar>
    </div>
  {/if}
  <div class="tabContentsArea" bind:this={tabContentsArea}>
    <div
      class="tabContentsWrapper {activeTab === tabs.荷受け
        ? 'tabContentsWrapper--pickup'
        : activeTab === tabs.荷下ろし
          ? 'tabContentsWrapper--unload'
          : ''}"
    >
      <div
        class="tabContents"
        bind:this={pickupContents}
        bind:offsetHeight={heightOfPickupContents}
      >
        <p class="img">
          <img src={sceneImagePickupAndSort} alt="荷受けの業務イメージ" />
        </p>

        <div class="inputAreaWrapper">
          <div class="inputArea">
            <DepotSelector
              qrHomeType={QrHomeTypes.PICKUP_AND_SORT}
              bind:selectedDepot
            />

            <div class="inputGroup">
              <p class="groupName">作業内容</p>
              <div class="typeArea">
                <FormField>
                  <Radio
                    bind:group={selectInTransitWork}
                    value={inTransitWorkTypes.BULK_RECEIVE}
                  />
                  <span slot="label" style="font-size: 16px;">一括荷受け</span>
                </FormField>
                <FormField>
                  <Radio
                    bind:group={selectInTransitWork}
                    value={inTransitWorkTypes.INDIVIDUAL_SCAN}
                  />
                  <span slot="label" style="font-size: 16px;">個別スキャン</span
                  >
                </FormField>
              </div>
            </div>
          </div>
        </div>

        <div class="acceptTakeover">
          <Button
            ripple={false}
            on:click={() => {
              initAudioContext();
              // 初めての画面表示時にヘルプを表示
              if (!appContext.firstTakeOverOpened) {
                helpContents = HelpTakeOverFromManager;
                helpBase = HelpBase;
                appContext.firstTakeOverOpened = true;
                appContext.store();
              }
              acceptTakeoverDialog.openDialog();
            }}
          >
            <Label>別の担当者が荷受けした荷物を引き継ぐ</Label>
          </Button>
        </div>

        <Label>
          <Button
            color="secondary"
            variant="unelevated"
            style="width: 300px; min-height: 50px; margin: 30px auto 10px; display: block;"
            on:click={() => {
              if (selectInTransitWork === inTransitWorkTypes.BULK_RECEIVE) {
                goToBulkReceivePage();
              } else {
                goToPackageScanPage();
              }
            }}
            disabled={!selectedDepot || selectInTransitWork === undefined}
          >
            {#if selectInTransitWork === inTransitWorkTypes.BULK_RECEIVE}
              一括荷受けを開始する
            {:else}
              荷物のスキャンを開始する
            {/if}
          </Button>
        </Label>

        {#if !inTransitDeliveryList?.length}
          <div class="finishWorkButtonArea">
            <Button
              color="secondary"
              ripple={false}
              on:click={() => {
                finishWorkDialog.openDialog();
              }}
            >
              <Label>幹線輸送業務を終了する</Label>
            </Button>
          </div>
        {/if}
      </div>
      <!-- IF 幹線輸送ドライバーによる荷受け業務時、かつ荷受けした荷物がある場合 -->
      <div
        class="tabContents"
        bind:this={unloadContents}
        bind:offsetHeight={heightOfUnloadContents}
      >
        <p class="img">
          <img src={sceneImageUnload} alt="荷下ろしの業務イメージ" />
        </p>
        <div class="scanCountInfoArea">
          <div class="totalScanCountArea">
            <p class="totalScanCount">
              {#if !unloadingMode}
                輸送中荷物の総数 {userContext
                  .getNumberOfInTransitPackages()
                  .toLocaleString()}個
              {:else}
                {centersMap?.get(unloadingTargetInfo.transportDestinationId)
                  ?.name ?? "【取得エラー】"}
                行のカゴ車 {unloadingTargetInfo.numberOfBasketCar.toLocaleString()}台
              {/if}
            </p>
          </div>

          <div class="tranceportSourceUnitArea">
            {#each inTransitDeliveryList as transportSourceInfo, i}
              {#if !unloadingMode || (unloadingMode && includeTargetDestination(transportSourceInfo.deliveryInfoList))}
                <div class="tranceportSourceUnit">
                  <p class="tranceportSourceName">
                    <strong
                      >{centersMap?.get(transportSourceInfo.transportSourceId)
                        ?.name ?? "【取得エラー】"}</strong
                    > 発
                  </p>
                  <div class="tranceportDestinationUnitArea">
                    {#each transportSourceInfo.deliveryInfoList as transportDestinationInfo, j}
                      {#if !unloadingMode || (unloadingMode && transportDestinationInfo.transportDestinationId === unloadingTargetInfo.transportDestinationId)}
                        <p class="tranceportDestinationName">
                          <strong
                            >{centersMap?.get(
                              transportDestinationInfo.transportDestinationId,
                            )?.name ?? "【取得エラー】"}</strong
                          >
                          行（カゴ車{transportDestinationInfo.basketCarList.length.toLocaleString()}台／荷物{getNumberOfInTransitPackagesByIndex(
                            inTransitDeliveryList,
                            i,
                            j,
                          ).toLocaleString()}個）
                        </p>
                        <Set
                          chips={transportDestinationInfo.basketCarList}
                          let:chip
                          nonInteractive
                        >
                          <Chip
                            {chip}
                            shouldRemoveOnTrailingIconClick={false}
                            style={chip.c
                              ? "background-color: #018786; color: white;"
                              : ""}
                            on:click={(event) => {
                              event.stopPropagation();

                              chip.c = !chip.c;
                              inTransitDeliveryList = inTransitDeliveryList;
                              if (chip.c && !unloadingMode) {
                                // 荷下ろしモードに切り替え
                                unloadingTargetInfo.transportDestinationId =
                                  transportDestinationInfo.transportDestinationId;
                                unloadingTargetInfo.numberOfBasketCar =
                                  getNumberOfInTransitBasketCars(
                                    transportDestinationInfo.transportDestinationId,
                                  );
                                unloadingMode = true;
                              } else if (!chip.c && unloadingMode) {
                                // 荷下ろしモードを解除判定
                                checkUnloadingModeRelease();
                              }
                            }}
                          >
                            <Text
                              >{chip.v
                                .reduce(function (
                                  sum,
                                  TrackingNumberAndQuantity,
                                ) {
                                  return (
                                    sum + TrackingNumberAndQuantity.quantity
                                  );
                                }, 0)
                                .toLocaleString()}個</Text
                            >
                            <TrailingIcon>
                              <span
                                class="material-icons md-18"
                                class:selected={chip.c}
                              >
                                check_circle
                              </span>
                            </TrailingIcon>
                          </Chip>
                        </Set>
                      {/if}
                    {/each}
                  </div>
                </div>
              {/if}
            {/each}

            {#if unloadingMode}
              <div class="requestTakeover">
                <Button
                  ripple={false}
                  on:click={loadingProgress.wrapAsync(async () => {
                    await requestTakeoverDialog.openDialog(
                      unloadingTargetInfo.transportDestinationId,
                    );
                  })}
                >
                  <Label>選択した荷物を別の担当者へ引き継ぐ</Label>
                </Button>
              </div>
            {/if}
          </div>

          <div class="scanCountInfoNoteArea">
            <p class="scanCountInfoNote">
              {#if !unloadingMode}
                輸送先で荷物を降ろす際に、対象のカゴ車の
                <span class="material-icons md-18 inlineIcon">
                  check_circle
                </span>
                マークをタップしてください。
              {:else}
                全てのカゴ車の荷下ろしを終えたら、『荷下ろし済みとして登録する』ボタンをタップしてください。
              {/if}
            </p>
          </div>
        </div>

        <Label>
          <Button
            color="secondary"
            variant="unelevated"
            style="width: 300px; min-height: 50px; margin: 30px auto 10px; display: block;"
            on:click={() => {
              unloadingConfirmDialog.openDialog();
            }}
            disabled={!unloadingMode}
          >
            荷下ろし済みとして登録する
          </Button>
        </Label>
      </div>
    </div>
  </div>
</div>

<div class="subContentsWrapper">
  <!-- 輸送中の荷物の引継受入ダイアログ -->
  <AcceptTakeoverDialog
    bind:this={acceptTakeoverDialog}
    bind:inTransitDeliveryList
    {centersMap}
    onTakeoverCompletedCallback={() => {
      activeTab = tabs.荷下ろし;
      userContext.inTransitDeliveryList = inTransitDeliveryList;
      toast.info($_("message.takeoverCompleted"));
    }}
  />

  <!-- 輸送中の荷物の引継要求ダイアログ -->
  <RequestTakeoverDialog
    bind:this={requestTakeoverDialog}
    bind:inTransitDeliveryList
    onTakeoverCompletedCallback={async () => {
      // 現在時刻を保持して現在位置を取得
      const requestedTimeStamp = Date.now();
      geolocator.requestCurrentPosition(false, requestedTimeStamp);
      await updateInTransitDeliveryList(requestedTimeStamp);
      if (!(await checkFinishWork())) {
        toast.info($_("message.takeoverCompleted"));
      }
    }}
  />

  <!-- 荷下ろし登録確認ダイアログ -->
  <ConfirmDialog
    bind:this={unloadingConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CANCEL_CLOSE}
    onDialogClosedHandler={async (event) => {
      if (event.detail.action === "ok") {
        await loadingProgress.wrapAsync(updateStatuses)();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content">
      {@const unloadingCount = getNumberOfUnloadingPackages()}
      カゴ車{unloadingCount.basketCarsCount.toLocaleString()}台（荷物{unloadingCount.packagesCount.toLocaleString()}個）を
      {centersMap
        .get(unloadingTargetInfo.transportDestinationId)
        ?.name?.concat?.("に") ?? ""}荷下ろし済みとして登録します。
      {#if unloadingCount.basketCarsCount < unloadingTargetInfo.numberOfBasketCar}
        <div class="unloadingOmissionNote">
          <p>
            {centersMap.get(unloadingTargetInfo.transportDestinationId)?.name ??
              "【取得エラー】"}行で未チェックのカゴ車が{(
              unloadingTargetInfo.numberOfBasketCar -
              unloadingCount.basketCarsCount
            ).toLocaleString()}台あります。登録を続行しますか？
          </p>
        </div>
      {/if}
    </svelte:fragment>
  </ConfirmDialog>
</div>

<!-- 業務終了登録ダイアログ -->
<FinishWorkDialog bind:this={finishWorkDialog} bind:messageType />

<style lang="scss">
  .contentsWrapper {
    max-height: 100%;
    position: relative;
    width: 100%;
  }
  .workTabs {
    width: calc(100% - 30px);
    margin: 0 auto 20px;
  }
  .tabContentsArea {
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .tabContentsWrapper {
    position: relative;
    width: 200%;
  }
  .tabContentsWrapper--pickup {
    left: 0;
  }
  .tabContentsWrapper--unload {
    left: -100%;
  }
  .tabContents {
    float: left;
    width: 50%;

    .finishWorkButtonArea {
      margin: 15px 0 0;
      text-align: center;

      :global(.mdc-button) {
        font-size: 13px;
      }
    }
  }

  .typeArea {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .acceptTakeover {
    margin: 3px 11px 0 0;
    text-align: right;

    :global(.mdc-button) {
      height: 26px;
      padding: 0 4px;
      font-size: 14px;
    }
  }

  .scanCountInfoArea {
    width: calc(100% - 30px);
    margin: 25px 15px 0;

    .totalScanCountArea {
      display: flex;
      width: 100%;
      // border-bottom: solid 1px #444444;
      padding-bottom: 6px;

      .totalScanCount {
        margin-left: 5px;
        font-size: 15px;
        font-weight: bold;
      }
    }

    .requestTakeover {
      margin-right: 8px;
      text-align: right;

      :global(.mdc-button) {
        height: 26px;
        padding: 0 4px;
        font-size: 13.5px;
      }
    }

    .tranceportSourceUnitArea {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px 5px;
      background-color: white;
      border: solid 0.5px #c7c7c7;
      max-height: 180px;
      overflow-y: auto;

      .tranceportSourceUnit {
        &:nth-of-type(n + 2) {
          margin-top: 10px;
        }

        .tranceportSourceName {
          margin-left: 5px;
          font-size: 14px;
        }

        .tranceportDestinationUnitArea {
          margin: 5px 0 0 5px;
          padding: 3px 0 0 2px;
          border-left: solid 5px #c9c7c7;

          .tranceportDestinationName {
            margin-left: 5px;
            margin-bottom: -3px;
            font-size: 14px;
          }

          .selected {
            color: white;
          }
        }
      }
    }

    .scanCountInfoNoteArea {
      margin: 5px 0 0 10px;

      .scanCountInfoNote {
        font-size: 14px;
        line-height: 1.2;

        .inlineIcon {
          vertical-align: middle;
        }
      }
    }
  }

  .unloadingOmissionNote {
    margin-top: 5px;
    padding: 4px 8px;
    background-color: #ffe8e8;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
  }
</style>
