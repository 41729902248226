<svg
  id="nonDelivery"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 258.07 258.07"
  class="svg-icons-non-delivery"
>
  <defs>
    <style>
      .non-delivery-1 {
        fill: #203a72;
      }

      .non-delivery-2 {
        fill: #14a0de;
      }
    </style>
  </defs>
  <g id="Layer_1">
    <path
      class="non-delivery-1"
      d="M129.04,30.5c-54.33,0-98.54,44.2-98.54,98.54s44.21,98.54,98.54,98.54,98.54-44.2,98.54-98.54S183.37,30.5,129.04,30.5ZM129.04,201.19c-39.78,0-72.15-32.37-72.15-72.15,0-15.51,4.97-29.86,13.33-41.64l100.46,100.46c-11.78,8.36-26.12,13.33-41.64,13.33ZM189.09,168.95l-99.97-99.97c11.44-7.63,25.16-12.1,39.91-12.1,39.78,0,72.15,32.37,72.15,72.15,0,14.75-4.47,28.47-12.09,39.91Z"
    />
    <path
      class="non-delivery-2"
      d="M129.04,0C57.89,0,0,57.89,0,129.03s57.89,129.04,129.04,129.04,129.04-57.88,129.04-129.04S200.19,0,129.04,0ZM129.04,242.53c-62.58,0-113.5-50.91-113.5-113.5S66.46,15.54,129.04,15.54s113.49,50.91,113.49,113.49-50.91,113.5-113.49,113.5Z"
    />
  </g>
</svg>
