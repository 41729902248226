/**
 * Lock class to provide a simple lock mechanism.
 */
class Lock {
  constructor() {
    this._locked = false;
    this._waiting = [];
  }

  /**
   * Acquire the lock. If the lock is already acquired, wait until it is released.
   */
  async acquire() {
    while (this._locked) {
      await new Promise((resolve) => this._waiting.push(resolve));
    }
    this._locked = true;
  }

  /**
   * Release the lock.
   */
  release() {
    this._locked = false;
    if (this._waiting.length > 0) {
      const resolve = this._waiting.shift();
      resolve();
    }
  }
}

export default Lock;
