const inTransitDeliveryListUtils = {
  /**
   * 輸送中リストに荷物を追加する
   * @param {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} inTransitDeliveryList 輸送中荷物リスト
   * @param {number} transportSourceId 輸送元拠点ID
   * @param {number} transportDestinationId 輸送先拠点ID
   * @param {import("~/libs/commonTypes").TrackingNumberAndQuantityList} trackingNumberAndQuantityList 追加する荷物情報
   */
  add(
    inTransitDeliveryList,
    transportSourceId,
    transportDestinationId,
    trackingNumberAndQuantityList,
  ) {
    // 輸送中荷物リストに今回の荷受け登録場所(輸送元)が既に登録されているか確認
    const sourceIndex = inTransitDeliveryList.findIndex(
      (item) => item.transportSourceId === transportSourceId,
    );
    if (sourceIndex >= 0) {
      // 輸送元が既に登録されている場合
      const transportSource = inTransitDeliveryList[sourceIndex];

      // 輸送中荷物リストに今回荷受けした荷物の輸送先が既に登録されているか確認
      const destinationIndex = transportSource.deliveryInfoList.findIndex(
        (item) => item.transportDestinationId === transportDestinationId,
      );
      if (destinationIndex >= 0) {
        // 輸送先が既に登録されている場合は今回スキャンした荷物情報を追加
        const destination = transportSource.deliveryInfoList[destinationIndex];
        const lastKey =
          destination.basketCarList[destination.basketCarList.length - 1].k;
        destination.basketCarList.push({
          k: lastKey + 1,
          v: trackingNumberAndQuantityList,
        });
      } else {
        // 輸送先が既に登録されていない場合は新たに輸送先情報を追加
        transportSource.deliveryInfoList.push({
          transportDestinationId: transportDestinationId,
          basketCarList: [{ k: 1, v: trackingNumberAndQuantityList }],
        });
      }
    } else {
      // 輸送元が登録されていない場合は新たに輸送元情報を追加
      const inTransitDeliveryInfo = {
        transportSourceId: transportSourceId,
        deliveryInfoList: [
          {
            transportDestinationId: transportDestinationId,
            basketCarList: [{ k: 1, v: trackingNumberAndQuantityList }],
          },
        ],
      };
      inTransitDeliveryList.push(inTransitDeliveryInfo);
    }
  },
};

export default Object.freeze(inTransitDeliveryListUtils);
