<script>
  import Button, { Label } from "@smui/button";
  import { getContext } from "svelte";

  import sceneImageTakebackPackageToDepot from "~/assets/images/sceneTakebackPackageToDepot.png";
  import { initAudioContext } from "~/libs/audio";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    QrHomeTypes,
  } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
  import { updateCenter } from "~/libs/stores";
  import DepotSelector from "~/pages/QrHome/DepotSelector.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** 選択された配送センター（「ID/名前」形式）[DepotSelectorの変数をbind] */
  let selectedDepot;

  /**
   * 荷物のスキャン画面へ遷移する。
   */
  function goToPackageScanPage() {
    initAudioContext();

    updateCenter.set(selectedDepot);

    pageRouter.moveToTakebackPackageToDepotWithQrCodeScan();

    appContext.store();
    userContext.store();
  }
</script>

<p class="img">
  <img src={sceneImageTakebackPackageToDepot} alt="業務イメージ" />
</p>

<div class="inputAreaWrapper">
  <div class="inputArea">
    <DepotSelector
      qrHomeType={QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT}
      bind:selectedDepot
    />
  </div>
</div>

<div class="captionLabel">
  <span class="material-icons"> info_outline </span>
  <p>
    読み取り画面にて、荷物ごとに持ち戻り対象・返品対象を選択することができます。
  </p>
</div>

<Label>
  <Button
    color="secondary"
    variant="unelevated"
    style="width: 300px; min-height: 50px; margin: 40px auto 10px;"
    on:click={goToPackageScanPage}
    disabled={!selectedDepot}
  >
    荷物のスキャンを開始する
  </Button>
</Label>

<style lang="scss">
  .captionLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    margin: 20px 15px 0;
    color: #018786;
    background-color: #01878616;
    border-radius: 8px;
    line-height: 1.3;

    .material-icons {
      font-size: 24px;
    }
  }
</style>
