<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Header, Title } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import imageCompression from "browser-image-compression";
  import { getContext } from "svelte";
  import { _ } from "svelte-i18n";

  import { CONTEXT_KEY_APP } from "~/libs/constants";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** 配達証明写真のFile @type {File} */
  export let podPhotoFile;

  /** AppContext @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** ダイアログの開閉フラグ */
  let dialogOpend = false;

  /** @type {HTMLVideoElement} */
  let videoElement;

  /** @type {HTMLCanvasElement} */
  let canvasElement;
  /** @type {CanvasRenderingContext2D} */
  let canvas;

  /**
   * ダイアログを開く。
   */
  export async function openDialog() {
    dialogOpend = true;

    loadingProgress.wrapAsync(async () => {
      // 使用するカメラを設定
      /** @type {MediaTrackConstraints} */
      const mediaTrackConstraints = {
        width: { max: 1000 },
        height: { max: 1000 },
      };
      if (
        appContext.useManualCameraSelection === true &&
        appContext.selectedBackCameraId
      ) {
        mediaTrackConstraints.deviceId = {
          exact: appContext.selectedBackCameraId,
        };
      } else {
        mediaTrackConstraints.facingMode = "environment";
      }

      // カメラを起動して`<video>`と紐付け
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: mediaTrackConstraints,
        });
        videoElement.srcObject = mediaStream;
        await videoElement.play();
      } catch (error) {
        console.error(error); // use non-logger explicitly
        toast.error($_("errors.failedToStartCamera") + error.message);
        return;
      }

      canvas = canvasElement.getContext("2d");
    })();
  }

  /**
   * ダイアログを閉じる。
   * @param {CustomEvent<{ action: "ok" | "cancel" | "close" }>} event
   */
  async function onCloseDialog(event) {
    try {
      if (event.detail.action === "ok") {
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        canvas.drawImage(videoElement, 0, 0);

        const currentEpochInMillis = Date.now();
        podPhotoFile = await imageCompression.canvasToFile(
          canvasElement,
          "image/jpeg",
          `${currentEpochInMillis}.jpg`,
          currentEpochInMillis,
        );
      }
    } finally {
      if (
        videoElement.srcObject &&
        videoElement.srcObject instanceof MediaStream
      ) {
        videoElement.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
        videoElement.srcObject = null;
      }
    }
  }
</script>

<div class="capturePhotoDialog">
  <Dialog
    bind:open={dialogOpend}
    class="wideWidthMdcDialog"
    scrimClickAction={""}
    escapeKeyAction={""}
    on:SMUIDialog:closed={onCloseDialog}
  >
    <Header>
      <Title>写真の撮影</Title>
      <IconButton
        action="close"
        ripple={false}
        class="material-icons"
        style="position: absolute; top: 5px; right: 5px;">close</IconButton
      >
    </Header>

    <Content>
      <video bind:this={videoElement} muted playsinline />
      <canvas bind:this={canvasElement} hidden />
    </Content>

    <Actions>
      <Button variant="unelevated" action="ok" disabled={!canvas}>
        <Label>撮影する</Label>
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .capturePhotoDialog {
    :global(.mdc-dialog__content) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      padding-bottom: 12px;
    }

    :global(.mdc-dialog__actions) {
      justify-content: center;
      margin: 0 12px 10px;
      border-top: 1px solid #ddd;
    }
    :global(.mdc-dialog__actions .mdc-button) {
      width: 180px;
      margin-top: 8px;
    }

    video {
      width: 100%;
      max-height: calc(100vh - 12px - 60px - 44px - 20px);
    }
  }
</style>
