<script>
  import { getContext, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";

  import sample from "~/assets/images/image_photo_sample.jpg";
  import { CONTEXT_KEY_APP, DropOffLocation } from "~/libs/constants";
  import imageFormatUtil from "~/libs/imageFormatUtil";
  import localStorages from "~/libs/localStorages";
  import { toast } from "~/libs/toast";
  import CapturePodPhotoFromVideoDialog from "~/pages/Update/PodPhotoTaker/CapturePodPhotoFromVideoDialog.svelte";

  /** 配達対象の荷物情報 @type {import("~/libs/commonTypes").DeliveryPackage} */
  export let deliveryPackage;

  /** 宅配ドライバーが選択した受け渡し方法 @type {number} */
  export let actualPackageDropPlace;

  /** 配達証明写真のFile @type {File} */
  export let podPhotoFile;

  /** AppContext @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /**
   * 配達証明写真のファイルに対応するBlob URL
   * ※配達証明写真のFileが変更された際にリアクティブに発行
   * @type {string}
   */
  $: podPhotoBlobUrl = (() => {
    if (podPhotoFile) {
      if (podPhotoBlobUrl) {
        URL.revokeObjectURL(podPhotoBlobUrl);
      }
      return URL.createObjectURL(podPhotoFile);
    } else {
      return undefined;
    }
  })();

  /**
   * 低解像度モードを有効（選択可能）にするか否か
   * ※手動でプロセスキルした場合なども該当するが妥協
   */
  const enablesLowResolutionMode =
    appContext.enablesLowResolutionPodPhotoMode ||
    localStorages.takePhotoInprogress;

  /** 「写真撮影のポイント」の表示フラグ */
  let showsTips = false;

  /** 低解像度モード（ビデオからキャプチャ）で写真撮影を行うためのダイアログ @type {CapturePodPhotoFromVideoDialog} */
  let capturePodPhotoFromVideoDialog;

  (() => {
    // 前回撮影中で終了していれば低解像度モードを有効にする
    if (localStorages.takePhotoInprogress) {
      appContext.enablesLowResolutionPodPhotoMode = true;
      appContext.store();
    }
    // 写真撮影中フラグをリセット
    localStorages.takePhotoInprogress = false;
  })();

  onDestroy(() => {
    // 配達証明写真のファイルに対応するBlob URLを発行している場合は破棄
    if (podPhotoBlobUrl) {
      URL.revokeObjectURL(podPhotoBlobUrl);
    }
  });

  /**
   * `<input capture>`のファイルが変更された際に呼び出されるイベントハンドラ
   * @param {Event} event
   */
  async function onInputPhotoChange(event) {
    /** @type {FileList} */
    const files = /** @type {HTMLInputElement} */ (event.target).files;
    if (files.length === 0) {
      return;
    }

    podPhotoFile = await imageFormatUtil.proofOfDeliveryPhotoChange(
      files[0],
      actualPackageDropPlace,
    );

    if (podPhotoFile === null) {
      toast.error($_("errors.failedFormatConversion"), { duration: 10000 });
      podPhotoFile = null;
      return;
    }
  }
</script>

{#if [/** @type {number} */ (DropOffLocation.BY_THE_DOOR), DropOffLocation.LOCKER, DropOffLocation.METERBOX, DropOffLocation.MAILBOX].includes(actualPackageDropPlace) || deliveryPackage.customer.signatureRequired}
  {@const isStandardDropOff = [
    /** @type {number} */ (DropOffLocation.BY_THE_DOOR),
    DropOffLocation.LOCKER,
    DropOffLocation.METERBOX,
    DropOffLocation.MAILBOX,
  ].includes(actualPackageDropPlace)}

  {@const buttonLabel = isStandardDropOff
    ? "置き配の写真を撮影する"
    : "配達票の写真を撮影する"}

  <div class="uploadButtonArea">
    {#if enablesLowResolutionMode}
      <CapturePodPhotoFromVideoDialog
        bind:this={capturePodPhotoFromVideoDialog}
        bind:podPhotoFile
      />
      <button
        class="uploadBtn"
        on:click={() => {
          capturePodPhotoFromVideoDialog.openDialog();
        }}
      >
        {buttonLabel}
      </button>
    {:else}
      <label class="uploadBtn">
        {buttonLabel}
        <input
          type="file"
          capture="environment"
          accept="image/jpeg"
          id="photoInput"
          on:click={() => {
            localStorages.takePhotoInprogress = true;
          }}
          on:change={() => {
            localStorages.takePhotoInprogress = false;
          }}
          on:cancel={() => {
            localStorages.takePhotoInprogress = false;
          }}
          on:change={onInputPhotoChange}
        />
      </label>
    {/if}

    {#if isStandardDropOff}
      <!-- 通常の置き配写真の場合 -->
      <button
        class="helpBtn"
        on:click={() => {
          showsTips = true;
        }}
      >
        <div style="width: 30px;">
          <span class="material-icons md-dark md-24">info_outline</span>
        </div>
      </button>
    {:else}
      <!-- 配達票写真の場合 -->
      <p class="captionText">※配達票全体が見えるように撮影してください</p>
    {/if}
  </div>

  {#if isStandardDropOff}
    <!-- 通常の置き配写真の撮影ポイント -->
    <div class="photoTips" style="display: {showsTips ? 'block' : 'none'}">
      <div class="photoContentWrap">
        <button
          class="photoCloseLabel"
          on:click={() => {
            showsTips = false;
          }}>×</button
        >
        <div class="photoContent">
          <div class="photoArea">
            <div class="photoTitle">
              <div class="icons" style="width: 30px; margin-right: 5px">
                <span class="material-icons md-dark md-24">help_outline</span>
              </div>
              <h3>写真撮影のポイント</h3>
            </div>
            <p class="photoSample">
              <img src={sample} alt="sample" style="width: 250px;" />
            </p>
            <ul class="photoCaption">
              <li>荷物が映っている。</li>
              <li>
                指定場所に置かれたことを確認できるよう、周囲（壁、床、地面）が映っている。
              </li>
              <li>
                荷物からの距離は2～3mくらいが目安。（ぽつんと置かれている感が出る）
              </li>
            </ul>
          </div>
        </div>
      </div>
      <label for="photoClose">
        <div class="photoBackground" />
      </label>
    </div>
  {/if}

  <!-- プレビュー画面（ファイルがアップロードされた時のみ表示） -->
  {#if podPhotoBlobUrl}
    <div class="previewArea">
      <div class="previewImg">
        <img src={podPhotoBlobUrl} alt="preview" style="width: 80%;" />
      </div>
    </div>
  {/if}

  <div class="border" />
{/if}

<style lang="scss">
  /* アップロードボタン */
  .uploadButtonArea {
    position: relative;

    .uploadBtn {
      display: inline-block;
      font-size: 16px;
      padding: 14px 30px;
      color: #fff;
      background-color: #018786;
      border: none;
      border-radius: 10px;
    }
    input[type="file"] {
      display: none;
    }
    .captionText {
      font-size: 0.8em;
      margin: 8px auto;
      color: #018786;
    }
    .helpBtn {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }

  /* 写真撮影のポイント */
  .photoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;

    .photoContentWrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 350px;
      background-color: #fefefe;
      z-index: 2;
      border-radius: 5px;
    }
    .photoCloseLabel {
      background-color: #777;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 20px;
      width: 36px;
      height: 36px;
      line-height: 1.5;
      text-align: center;
      display: table-cell;
      position: fixed;
      top: -15px;
      right: -2%;
      z-index: 99999;
      font-size: 1.4em;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
    .photoContent {
      max-height: 60vh;
      overflow-y: auto;
      padding: 30px 45px;
    }
    .photoArea {
      position: relative;
      margin: 0 auto;
      padding: 20px 0;
      background-color: #fff;
      border-radius: 10px;
    }
    .photoTitle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .photoTitle h3 {
      font-size: 18px;
    }
    .photoSample {
      margin: 20px auto;
    }
    .photoCaption {
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
      list-style-type: circle;
    }
    .photoCaption li {
      padding-top: 10px;
    }
    .photoBackground {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 1;
    }
  }

  /* プレビュー画面 */
  .previewArea {
    width: 94%;
    margin: 10px auto;
    padding: 20px 0;
    background-color: #e7f4f4;
    border-radius: 4px;
  }
</style>
