/**
 * 指定された輸送元センターから指定された輸送先センターへの輸送中荷物の総個数を返す。
 * @param {Array<import("~/libs/commonTypes").InTransitDeliveryInfo>} inTransitDeliveryList
 * @param {number} transportSourceIndex
 * @param {number} transportDestinationIndex
 * @returns {number}
 */
export function getNumberOfInTransitPackagesByIndex(
  inTransitDeliveryList,
  transportSourceIndex,
  transportDestinationIndex,
) {
  let count = 0;
  for (const basketCar of inTransitDeliveryList[transportSourceIndex]
    .deliveryInfoList[transportDestinationIndex].basketCarList) {
    for (const trackingNumberAndQuantity of basketCar.v) {
      count += trackingNumberAndQuantity.quantity;
    }
  }
  return count;
}
