<script>
  import Button, { Label } from "@smui/button";
  import SegmentedButton, { Segment } from "@smui/segmented-button";
  import { formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import sceneImageOutForDelivery from "~/assets/images/sceneOutForDelivery.png";
  import sceneImagePickupAndSort from "~/assets/images/scenePickupAndSort.png";
  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import { OfflineException } from "~/libs/backendApi";
  import {
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    CORE_DELIVERY_ROLE,
    ConfirmDialogTypes,
    DRIVER_ROLE,
    DriverType,
    QrHomeTypes,
  } from "~/libs/constants";
  import geolocator from "~/libs/geolocator";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import pageRouter from "~/libs/pageRouter";
  import { currentPositionStore } from "~/libs/stores";
  import { switchRole } from "~/libs/switchRole";
  import { toast } from "~/libs/toast";
  import workManage from "~/libs/workManage";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  let time = new Date();

  /** @type {ConfirmDialog} 確認ダイアログコンポーネントのインスタンス */
  let dialog;

  /**
   * 切り替え可能なロールのリスト
   * 現時点では固定で宅配業務と幹線輸送業務のみ
   * @type {Array<DRIVER_ROLE | CORE_DELIVERY_ROLE>}
   */
  let switchableRoles = [DRIVER_ROLE, CORE_DELIVERY_ROLE];

  /** 選択中ロールのindex @type {DRIVER_ROLE | CORE_DELIVERY_ROLE} */
  let selectedRole =
    // ロール切替え可能かつ前回のロールが幹線輸送業務の場合は幹線輸送業務を選択状態にする
    userContext.canSwitchRole() &&
    appContext.previousRole === CORE_DELIVERY_ROLE
      ? CORE_DELIVERY_ROLE
      : // ロール切替え可能かつ前回のロールが宅配業務の場合は宅配業務を選択状態にする
        userContext.canSwitchRole() && appContext.previousRole === DRIVER_ROLE
        ? DRIVER_ROLE
        : // ロール切替え可能かつ前回のロールが未設定の場合は未選択状態にする
          userContext.canSwitchRole()
          ? null
          : // ロール切替え不可の場合はログインユーザーのロールを設定
            userContext.hasDriverRole()
            ? DRIVER_ROLE
            : CORE_DELIVERY_ROLE;

  $: formattedToday = formatDate(time, "yyyy年MM月dd日 EEEE", {
    locale: localeJa,
  });
  $: hours = String(time.getHours()).padStart(2, "0"); // 時間
  $: minutes = String(time.getMinutes()).padStart(2, "0"); // 分

  onMount(() => {
    if (!$currentPositionStore) {
      // 現在地情報が取得できていない場合は取得可否を再確認
      checkAvailabilityCurrentPosition();
    }

    // 表示時刻を1秒毎に更新
    const interval = setInterval(() => {
      time = new Date();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  /**
   * 業務開始処理
   */
  async function beginWork() {
    /** @type {DriverType} */
    let driverType;

    try {
      if (selectedRole === CORE_DELIVERY_ROLE) {
        // 幹線輸送業務を開始する場合
        if (userContext.hasDriverRole()) {
          // 宅配業務ロールでログイン中の場合ロール切替え
          await switchRole(userContext, CORE_DELIVERY_ROLE);
        }
        driverType = DriverType.CORE_DELIVERY;
      } else {
        // 宅配業務を開始する場合
        if (userContext.hasContractDriverRole()) {
          // 幹線輸送ロールでログイン中の場合ロール切替え
          await switchRole(userContext, DRIVER_ROLE);
        }
        driverType = DriverType.DRIVER;
      }

      if (userContext.canSwitchRole()) {
        // ロール切替え可能な場合は選択したロールを保存
        appContext.previousRole = selectedRole;
        appContext.store();
      }

      // 業務開始登録
      await workManage.begin(userContext, driverType);

      // ページ遷移
      if (selectedRole === DRIVER_ROLE) {
        pageRouter.moveToList();
      } else {
        pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
      }
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
      } else if (error instanceof OfflineException) {
        toast.error($_("errors.offline"));
      } else {
        // サーバーエラー応答等が発生した場合
        logger.error(
          "[BeginWork] ロール切替えでエラーが発生しました",
          {
            username: userContext.loginUser?.username,
          },
          error,
        );
        toast.error($_("errors.defaultMessage"));
      }
    }
  }

  /**
   * 位置情報の利用可否を判断し、利用できない場合はダイアログを表示する
   */
  async function checkAvailabilityCurrentPosition() {
    geolocator.requestCurrentPosition(false);
    await loadingProgress.wrapAsync(async () => {
      for (let count = 0; count < 10; count++) {
        await sleep(500);
        if ($currentPositionStore) {
          // 位置情報が取得できた場合はダイアログを閉じる
          dialog.closeDialog();
          return;
        }
      }
      // 位置情報が取得できなかった場合はダイアログを表示
      dialog.openDialog();
    })();
  }
</script>

<div class="wrapper">
  <button
    class="logout"
    on:click={() => {
      pageRouter.moveToLogin();
    }}
  >
    <span class="material-icons md-18">logout</span>
  </button>

  <div class="calenderArea">
    <p class="date">
      {formattedToday}
    </p>
    <div class="clock" role="timer" aria-live="polite" aria-atomic="true">
      {hours}:{minutes}
    </div>
  </div>

  <div class="imageArea">
    {#if selectedRole === CORE_DELIVERY_ROLE}
      <p class="img" transition:fade>
        <img src={sceneImagePickupAndSort} alt="幹線輸送業務" />
      </p>
    {:else}
      <p class="img" transition:fade>
        <img src={sceneImageOutForDelivery} alt="宅配業務" />
      </p>
    {/if}
  </div>

  {#if userContext.canSwitchRole()}
    <div class="roleArea">
      {#if userContext.canSwitchRole() && !appContext.previousRole}
        <p class="roleNotice">どちらの業務を始めるか選択してください</p>
      {/if}
      <div class="roleButtons">
        <SegmentedButton
          segments={switchableRoles}
          let:segment
          singleSelect
          bind:selected={selectedRole}
        >
          <Segment {segment}>
            <Label>{$_(`classes.businessShortName.${segment}`)}</Label>
          </Segment>
        </SegmentedButton>
      </div>
    </div>
  {/if}

  <Button
    variant="unelevated"
    color="secondary"
    style="width: 280px; min-height: 60px; margin-top: 25px; font-size: 22px;"
    disabled={selectedRole === null}
    on:click={loadingProgress.wrapAsync(beginWork)}
  >
    <Label>業務を開始する</Label>
  </Button>
</div>

<div class="FailedGetCurrentPosition">
  <ConfirmDialog
    bind:this={dialog}
    type={ConfirmDialogTypes.NONE}
    mandatory={true}
  >
    <svelte:fragment slot="title">位置情報が取得できません</svelte:fragment>
    <svelte:fragment slot="content">
      <span
        >アプリに位置情報の利用を許可していない場合は、設定を変更してから再読み込みを行ってください。</span
      >

      <Button
        class="registerButton"
        color="secondary"
        variant="outlined"
        on:click={loadingProgress.wrapAsync(checkAvailabilityCurrentPosition)}
        style="width: 100%; margin-top: 15px;"
      >
        <Label>再読み込みを行う</Label>
      </Button>
    </svelte:fragment>
  </ConfirmDialog>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logout {
      position: fixed;
      border: none;
      background: #dadada;
      top: 15px;
      left: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .calenderArea {
      color: #424242;
      text-align: center;
      margin-bottom: 14vh;

      .date {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .clock {
        font-size: 54px;
      }
    }

    .imageArea {
      position: relative;
      width: 100%;
      height: 20vh;

      .img {
        position: absolute;
        width: 100%;
      }

      :global(p.img img) {
        width: 100%;
      }
    }

    .roleArea {
      margin-top: 40px;

      .roleNotice {
        padding-bottom: 10px;
        font-size: 15px;
        color: var(--mdc-theme-error);
      }

      .roleButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        :global(.mdc-segmented-button__label) {
          margin: 0 25px;
          font-size: 20px;
        }
      }
    }
  }
</style>
