import backendApi from "~/libs/backendApi";

/** @type {Array<import("~/libs/commonTypes").Company>} キャッシュ済の会社一覧 */
let companiesCache = [];

/** @type {number} キャッシュの最終更新日時 */
let lastUpdated = 0;

const companies = {
  /**
   * 会社一覧(EC事業者のみ)を返します。
   * @param {boolean} forceLatest 強制的に最新の一覧を取得する場合はtrue
   * @returns {Promise<Array<import("~/libs/commonTypes").Company>>} 会社一覧
   */
  async get(forceLatest = false) {
    const currentTime = Date.now();
    if (
      companiesCache.length === 0 ||
      forceLatest ||
      currentTime - lastUpdated >= 1000 * 60 * 60 * 12
    ) {
      // キャッシュがない場合、強制取得フラグが立っている場合、もしくは前回取得から12時間以上経過している場合はawaitで取得
      companiesCache = await backendApi.getEcCompanies();
      lastUpdated = currentTime;
    } else if (currentTime - lastUpdated >= 1000 * 60 * 30) {
      // キャッシュがある場合はキャッシュを返すとともに非同期でキャッシュを更新（前回取得から30分以上経過している場合）
      backendApi
        .getEcCompanies()
        .then((companies) => {
          companiesCache = companies;
          lastUpdated = currentTime;
        })
        .catch((error) => {
          console.warn(error); // use non-logger explicitly
        });
    }
    // キャッシュをディープコピーして返す
    return companiesCache.map((company) => {
      const newCompany = { ...company };
      return newCompany;
    });
  },
};

export default Object.freeze(companies);
