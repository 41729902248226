<script>
  import { getContext, onDestroy } from "svelte";

  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpOfflineMode from "~/components/help/HelpOfflineMode.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import { CONTEXT_KEY_USER, OfflineModeTypes } from "~/libs/constants";
  import { displayOfflineModeHelp } from "~/libs/stores";
  import OfflineFooter from "~/pages/Offline/OfflineFooter.svelte";
  import OfflineHeader from "~/pages/Offline/OfflineHeader.svelte";
  import OfflineUpdate from "~/pages/Offline/OfflineUpdate.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** オフラインモードの種別 @type {import("~/libs/constants").OfflineModeTypes} */
  export let offlineModeType;

  /** オフラインモードヘルプを表示するか否か */
  let showsOfflineModeHelp = false;
  /** displayOfflineModeHelpのUnsubscriver @type {import("svelte/store").Unsubscriber} */
  const displayOfflineModeHelpUnsubscriber = displayOfflineModeHelp.subscribe(
    (display) => {
      if (display) {
        showsOfflineModeHelp = true;
      }
    },
  );

  onDestroy(async () => {
    displayOfflineModeHelpUnsubscriber?.();
  });
</script>

<div class="mainContentsWrapper">
  <OfflineHeader>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">
      {#if offlineModeType === OfflineModeTypes.DELIVERED}
        配達登録（オフラインモード）
      {:else}
        荷受け登録（オフラインモード）
      {/if}
    </svelte:fragment>
  </OfflineHeader>

  <main>
    {#if offlineModeType === OfflineModeTypes.DELIVERED}
      <OfflineUpdate />
    {/if}
  </main>

  <OfflineFooter />
</div>

<!-- ヘルプ表示 -->
{#if showsOfflineModeHelp}
  <HelpBase
    helpContents={HelpOfflineMode}
    clickConfirm={() => {
      showsOfflineModeHelp = false;
      displayOfflineModeHelp.set(false);
    }}
  />
{/if}

<style lang="scss">
  main {
    width: 100%;
    height: 100%;
  }
</style>
