<script>
  import { escape } from "html-escaper";
  import L from "leaflet";
  import { getContext, onMount, tick } from "svelte";

  import depotIconImage from "~/assets/images/leaflet/depot-39x31.png";
  import locationIconImage from "~/assets/images/leaflet/location-26x41.png";
  import targetIconImage from "~/assets/images/leaflet/target-41x41.png";
  import addressUtils from "~/libs/addressUtils";
  import { AddressTypeForMap, CONTEXT_KEY_APP } from "~/libs/constants";
  import depotLocations from "~/libs/depotLocations";
  import { currentPositionStore } from "~/libs/stores";

  import "leaflet/dist/leaflet.css"; // LeafletのCSSを読込

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** 配達リスト @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  export let deliveryList;

  /** 配達場所用のマーカーアイコン */
  // @ts-ignore
  const LocationIcon = L.Icon.extend({
    options: {
      iconUrl: locationIconImage,
      iconSize: [26, 41],
      iconAnchor: [13, 36],
      popupAnchor: [0, -40],
      tooltipAnchor: [16, -28],
      className: "leaflet-location-icon",
    },

    createIcon: function () {
      const wrapperDiv = document.createElement("div");
      const numberDiv = document.createElement("div");
      const iconImg = this._createImg(this.options["iconUrl"]);
      numberDiv.setAttribute("class", "number");
      numberDiv.textContent = this.options["number"] ?? "";
      wrapperDiv.appendChild(iconImg);
      wrapperDiv.appendChild(numberDiv);
      this._setIconStyles(wrapperDiv, "icon");
      return wrapperDiv;
    },

    createShadow: function () {
      return null;
    },
  });

  /** 現在地用のマーカーアイコン */
  const targetIcon = L.icon({
    iconUrl: targetIconImage,
    iconSize: [41, 41],
    iconAnchor: [21, 21],
    popupAnchor: [0, -24],
    tooltipAnchor: [16, -28],
    className: "leaflet-target-icon",
  });

  /** 配送センター用のマーカーアイコン */
  const depotIcon = L.icon({
    iconUrl: depotIconImage,
    iconSize: [39, 31],
    iconAnchor: [20, 16],
    popupAnchor: [0, -20],
    tooltipAnchor: [16, -28],
    className: "leaflet-depot-icon",
  });

  onMount(async () => {
    await tick();

    // 取得済の現在地を取り出し。List側で未取得のパターンを弾いているので、ここでは必ず取得できる
    const currentPosition = $currentPositionStore;

    // 現在地を中心としたZoomレベル12の地図を作成
    const map = L.map("routeMap").setView(
      [currentPosition.coords.latitude, currentPosition.coords.longitude],
      12,
    );

    /**
     * 地理院タイルの種類
     * @type {"std" | "pale" | "blank" | "seamlessphoto"}
     * @see https://maps.gsi.go.jp/development/ichiran.html
     */
    const mapType = "pale";

    // 地理院タイルを地図に追加
    L.tileLayer(
      `https://cyberjapandata.gsi.go.jp/xyz/${mapType}/{z}/{x}/{y}.png`,
      {
        minZoom: 5,
        maxZoom: 18,
        attribution: `<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank" rel="noreferrer">国土地理院</a>`,
      },
    ).addTo(map);
    map.attributionControl.setPrefix(false);

    // 現在地を描画
    L.marker(
      [currentPosition.coords.latitude, currentPosition.coords.longitude],
      { icon: targetIcon },
    )
      .bindPopup("現在地")
      .addTo(map);

    /** 持ち出し配送センターIDのSet @type {Set<number>} */
    const depotList = new Set();

    // 未配達の荷物のマーカーを描画
    let currentPackageNumber = 1;
    for (const deliveryPackage of deliveryList?.filter(
      (e) => e.statusText === "未",
    ) ?? []) {
      if (deliveryPackage.latlon) {
        L.marker(
          [deliveryPackage.latlon.latitude, deliveryPackage.latlon.longitude],
          {
            icon: new LocationIcon({
              number:
                appContext.fixNumberOnDeliveryList === false
                  ? String(currentPackageNumber)
                  : deliveryPackage.numberOnDeliveryList,
            }),
          },
        )
          .bindPopup(
            (deliveryPackage.latlon?.isLowAccuracy
              ? `<span style="color: red; font-weight: 600;">【低精度 (市区町村・町丁目レベル)】</span><br />`
              : "") +
              escape(resolveReceiverAddress(deliveryPackage, "\n")).replace(
                /\n/g,
                "<br />",
              ) +
              "<br />" +
              (await getNaviPopupContent(
                resolveReceiverAddress(deliveryPackage, " "),
                deliveryPackage.receiverPostcode,
              )),
          )
          .addTo(map);
      }

      if (Number.isInteger(deliveryPackage.outForDeliveryLocationId)) {
        depotList.add(deliveryPackage.outForDeliveryLocationId);
      }

      currentPackageNumber++;
    }

    // 配送センターのマーカーを描画
    try {
      const depotLocationMap = await depotLocations.getCentersMap();
      for (const depotId of depotList) {
        const depot = depotLocationMap.get(depotId);
        if (depot) {
          L.marker([depot.latitude, depot.longitude], {
            icon: depotIcon,
          })
            .bindPopup(
              escape(depot.name) +
                "<br />" +
                (await getNaviPopupContent(
                  `${depot.latitude},${depot.longitude}`,
                )),
            )
            .addTo(map);
        }
      }
    } catch (error) {
      console.warn(error); // use non-logger explicitly
    }
  });

  /**
   * 荷物からお届け先住所を解決する。
   * @param {import("~/libs/commonTypes").DeliveryPackage} deliveryPackage 荷物情報
   * @param {string} addressSeparator 住所の区切り文字
   * @returns {string} お届け先住所
   */
  function resolveReceiverAddress(deliveryPackage, addressSeparator) {
    if (deliveryPackage.addressForMap === AddressTypeForMap.INPUTTED) {
      return deliveryPackage.enteredAddress;
    } else if (deliveryPackage.addressForMap === AddressTypeForMap.CORRECTED) {
      return deliveryPackage.correctedReceiverAddress;
    } else {
      let address = deliveryPackage.receiverAddress2
        ? deliveryPackage.receiverAddress1 +
          addressSeparator +
          deliveryPackage.receiverAddress2
        : deliveryPackage.receiverAddress1;

      if (deliveryPackage.shippingKnowledge?.byAddress?.memo) {
        const MEMO_PREFIX = "地図表示用住所 ";
        const addressLine = deliveryPackage.shippingKnowledge.byAddress.memo
          .split(/\r?\n/)
          .find((m) => m.startsWith(MEMO_PREFIX));
        if (addressLine) {
          address = addressLine.replace(MEMO_PREFIX, "");
        }
      }
      return address;
    }
  }

  /**
   * Googleマップナビ用のHTMLコンテンツを取得する。
   * @param {string} destination 目的地
   * @param {string} [destinationPostcode] 目的地の郵便番号
   * @returns {Promise<string>} Googleマップナビ用のHTMLコンテンツ
   */
  async function getNaviPopupContent(destination, destinationPostcode) {
    const encodedDestination = encodeURIComponent(
      await addressUtils.normalizeAddressForGoogleMap(
        destination,
        destinationPostcode,
      ),
    );
    return (
      `<a href="https://maps.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodedDestination}" target="_blank" rel="noreferrer">` +
      '<span class="material-icons md-18">directions</span>Googleマップでナビ</a>'
    );
  }
</script>

<div id="routeMap"></div>

<style lang="scss">
  #routeMap {
    width: 100%;
    height: 100%;

    :global(.leaflet-location-icon) {
      background: transparent;
      border: none;
    }
    :global(.leaflet-location-icon .number) {
      position: relative;
      top: -41px;
      width: 26px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
    }

    :global(.leaflet-popup-content .material-icons) {
      padding-right: 2px;
      vertical-align: bottom;
    }
  }
</style>
