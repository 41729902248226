<svg
  id="Unloading_2"
  data-name="Layer_2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 321.79 247.99"
  class="svg-icons-unloading"
>
  <defs>
    <style>
      .unloading-1 {
        fill: #203a72;
      }

      .unloading-2 {
        fill: #14a0de;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer_1">
    <g>
      <path
        class="unloading-2"
        d="M99.92,100.91h-37.28c-3.34,0-6.05,2.71-6.05,6.05v24.01c.1.15.15.24.24.34,2.18,1.36,8.96,2.57,14.09,2.47,9.3-.24,17.04,7.17,17.28,16.46h11.72c3.34,0,6.05-2.71,6.05-6.05v-37.23c0-3.34-2.71-6.05-6.05-6.05Z"
      />
      <rect
        class="unloading-2"
        x="121.84"
        y="198.53"
        width="49.37"
        height="49.37"
        rx="6.06"
        ry="6.06"
      />
      <rect
        class="unloading-2"
        x="121.84"
        y="139.56"
        width="49.37"
        height="49.37"
        rx="6.06"
        ry="6.06"
      />
      <rect
        class="unloading-2"
        x="180.89"
        y="198.53"
        width="49.37"
        height="49.37"
        rx="6.06"
        ry="6.06"
      />
      <rect
        class="unloading-2"
        x="180.89"
        y="139.56"
        width="49.37"
        height="49.37"
        rx="6.06"
        ry="6.06"
      />
      <rect
        class="unloading-2"
        x="180.89"
        y="80.58"
        width="49.37"
        height="49.37"
        rx="6.06"
        ry="6.06"
      />
      <circle
        class="unloading-1"
        cx="28.59"
        cy="86.71"
        r="19.38"
        transform="translate(-19.16 8.89) rotate(-13.28)"
      />
      <path
        class="unloading-1"
        d="M71.09,142c-6.86.17-17.79-1.61-20.55-5.31-3.24-4.36-5.6-9.74-7-13.48-2.75-7.35-9.6-12.33-17.46-12.68-5.3-.23-10.55,1.73-14.39,5.4-3.84,3.67-6.05,8.81-6.06,14.12l-.87,42.85c-.02.61,0,1.22.1,1.82l4.45,29.62L.38,235.64c-1.47,5.14,1.51,10.5,6.65,11.97.89.26,1.78.38,2.67.38,4.21,0,8.09-2.77,9.3-7.02l9.53-33.33c.38-1.33.47-2.73.26-4.1l-3.76-25.02,13.61,26.91,2.74,33.66c.43,5.33,5.1,9.3,10.44,8.86,5.33-.43,9.3-5.11,8.86-10.44l-2.9-35.55c-.1-1.25-.44-2.46-1.01-3.58l-13.87-27.45-.14-18.17c10.4,6.71,25.35,6.75,28.77,6.66,4.81-.13,8.59-4.12,8.48-8.92-.12-4.8-3.99-8.58-8.91-8.5Z"
      />
      <path
        class="unloading-1"
        d="M121.84,39.23v21.09c0,3.21,2.6,5.81,5.81,5.81h117.13v176.05c0,3.21,2.6,5.81,5.81,5.81h65.39c3.21,0,5.81-2.6,5.81-5.81V5.81c0-3.61-3.27-6.35-6.82-5.72L126.63,33.51c-2.77.49-4.79,2.9-4.79,5.72Z"
      />
    </g>
  </g>
</svg>
