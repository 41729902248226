<svg
  id="Select"
  class="svg-icons-select"
  data-name="Layer_2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 310.63 252.52"
  height="52"
  width="52"
>
  <defs>
    <style>
      .cls-1 {
        fill: #203a72;
      }

      .cls-2 {
        fill: #14a0de;
      }
    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer_1">
    <g>
      <path
        class="cls-2"
        d="M119.96,93.37h11.16v-21.7c0-13.34,10.84-24.21,24.17-24.21,1.62,0,3.26.16,4.81.46,11.23,2.27,19.4,12.45,19.4,24.21v21.24h11.16c6.26,0,11.33-5.07,11.33-11.33V11.33c0-6.26-5.07-11.33-11.33-11.33h-70.71c-6.26,0-11.33,5.07-11.33,11.33v70.71c0,6.26,5.07,11.33,11.33,11.33Z"
      />
      <path
        class="cls-2"
        d="M82.04,0H11.31C5.06,0,0,5.07,0,11.32v70.73c0,6.25,5.06,11.31,11.31,11.31h70.73c6.24,0,11.31-5.06,11.31-11.31V11.32C93.35,5.07,88.28,0,82.04,0ZM78.53,78.53H14.82V14.83h63.71v63.71Z"
      />
      <path
        class="cls-2"
        d="M299.32,0h-70.73c-6.24,0-11.31,5.06-11.31,11.31v70.73c0,6.25,5.06,11.31,11.31,11.31h70.73c6.24,0,11.31-5.06,11.31-11.31V11.32C310.63,5.07,305.57,0,299.32,0ZM295.81,78.53h-63.71V14.83h63.71v63.71Z"
      />
      <path
        class="cls-1"
        d="M253.7,135.58c-.82-.16-1.64-.24-2.44-.24-6.81,0-12.34,5.52-12.34,12.31v.55c0,2.02-1.64,3.65-3.65,3.65s-3.65-1.64-3.65-3.65v-8.65c0-6.81-5.54-12.34-12.34-12.34-.8,0-1.62.08-2.44.23-5.74,1.1-9.91,6.37-9.91,12.53v2.98c0,2.01-1.64,3.65-3.65,3.65s-3.65-1.64-3.65-3.65v-10.45c0-6.79-5.54-12.3-12.34-12.3-.21,0-.42,0-.65.02-6.43.33-11.66,6.12-11.66,12.92v5.08c0,2.01-1.65,3.65-3.69,3.65s-3.65-1.64-3.65-3.65v-66.08c0-6.15-4.15-11.44-9.85-12.58-.82-.17-1.66-.25-2.5-.25-6.78,0-12.3,5.54-12.3,12.35v95.46c0,1.66-1.36,2.56-2.63,2.56-.57,0-1.38-.18-2.03-1.01l-17.29-23.2c-1.98-2.63-4.48-4.44-7.2-5.23-1.21-.36-2.53-.56-3.83-.56-2.71,0-5.33.79-7.62,2.31-5.49,3.83-7.3,11.33-4.22,17.45l34.29,67.09c8.19,16.04,24.48,26,42.49,26h40.98c12.27,0,23.95-4.62,32.89-13,9-8.44,14.23-19.79,14.75-31.97.02-.53.04-1.03.04-1.48v-57.95c0-6.17-4.17-11.44-9.91-12.54Z"
      />
    </g>
  </g>
</svg>
