import { decode } from "base45-ts";
import JSBI from "jsbi";

const BI7 = JSBI.BigInt(7);
const BI8 = JSBI.BigInt(8);
const BI10 = JSBI.BigInt(10);
const BI16 = JSBI.BigInt(16);
const BI24 = JSBI.BigInt(24);
const BI32 = JSBI.BigInt(32);

/**
 * QRコードに埋め込まれた送り状番号(Base45文字列)を通常の送り状番号に変換します。
 * @param {string} embeddedTrackingNumber QRコードに埋め込まれた送り状番号
 * @returns {string} 送り状番号（12桁）
 * @throws {TypeError} 送り状番号の形式が不正な場合
 */
export function parseQRCodeEmbeddedTrackingNumber(embeddedTrackingNumber) {
  try {
    if (
      (embeddedTrackingNumber.length == 12 ||
        embeddedTrackingNumber.length == 14) &&
      embeddedTrackingNumber.startsWith("SCC")
    ) {
      const decodedBytes = decode(
        embeddedTrackingNumber.length == 12
          ? embeddedTrackingNumber.substring(4)
          : embeddedTrackingNumber.substring(4, 12),
      );
      if (decodedBytes.length == 5) {
        const trackingNumber = JSBI.add(
          JSBI.add(
            JSBI.add(
              JSBI.add(
                JSBI.leftShift(JSBI.BigInt(decodedBytes[0]), BI32),
                JSBI.leftShift(JSBI.BigInt(decodedBytes[1]), BI24),
              ),
              JSBI.leftShift(JSBI.BigInt(decodedBytes[2]), BI16),
            ),
            JSBI.leftShift(JSBI.BigInt(decodedBytes[3]), BI8),
          ),
          JSBI.BigInt(decodedBytes[4]),
        );
        if (
          JSBI.equal(
            JSBI.remainder(JSBI.divide(trackingNumber, BI10), BI7),
            JSBI.remainder(trackingNumber, BI10),
          )
        ) {
          return trackingNumber.toString().padStart(12, "0");
        } else {
          console.log(
            "[trackingNumberUtils.js] parseQRCodeEmbeddedTrackingNumber; invalid checksum:",
            embeddedTrackingNumber,
          );
        }
      }
    }
  } catch (error) {
    console.log(
      "[trackingNumberUtils.js] parseQRCodeEmbeddedTrackingNumber:",
      error,
    );
  }
  throw new TypeError("送り状番号の形式が不正です: " + embeddedTrackingNumber);
}

/**
 * Codabarに埋め込まれた送り状番号（開始・終了コード含む）を通常の送り状番号に変換します。
 * @param {string} embeddedTrackingNumber Codabarに埋め込まれた送り状番号
 * @returns {string} 送り状番号（12桁）
 * @throws {TypeError} 送り状番号の形式が不正な場合
 */
export function parseCodabarEmbeddedTrackingNumber(embeddedTrackingNumber) {
  try {
    if (
      embeddedTrackingNumber.length == 14 &&
      /^[a-dA-D]\d{12}[a-dA-D]$/.test(embeddedTrackingNumber)
    ) {
      const trackingNumber = embeddedTrackingNumber.substring(1, 13);
      if (verifyTrackingNumberChecksum(trackingNumber)) {
        return trackingNumber;
      }
    }
  } catch (error) {
    console.warn(error);
  }
  throw new TypeError(
    `コードが平面になるようにして読み直してください。${embeddedTrackingNumber}`,
  );
}

/**
 * 追跡番号のチェックサムを検証します。
 * @param {string} trackingNumber 追跡番号
 * @returns {boolean} 検証結果（正常：true）
 */
export function verifyTrackingNumberChecksum(trackingNumber) {
  const longTrackingNumber = JSBI.BigInt(trackingNumber);
  return JSBI.equal(
    JSBI.remainder(JSBI.divide(longTrackingNumber, BI10), BI7),
    JSBI.remainder(longTrackingNumber, BI10),
  );
}

/**
 * 追跡番号をハイフン付きにフォーマットして返します。
 * @param {string} trackingNumber 追跡番号
 * @returns {string} 4桁ごとにハイフン(-)で区切られた追跡番号
 */
export function formatTrackingNumber(trackingNumber) {
  return trackingNumber.replace(/([0-9]{4})([0-9]{4})([0-9]{4})/, "$1-$2-$3");
}
