<svg
  id="deliveryComplete"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 254.29 254.28"
  class="svg-icons-delivery-complete"
>
  <defs>
    <style>
      .delivery-complete-1 {
        fill: #203a72;
      }

      .delivery-complete-2 {
        fill: #14a0de;
      }
    </style>
  </defs>
  <g id="Layer_1">
    <g>
      <path
        class="delivery-complete-1"
        d="M127.14,28.4c-54.54,0-98.76,44.21-98.76,98.75s44.22,98.76,98.76,98.76,98.76-44.21,98.76-98.76S181.68,28.4,127.14,28.4ZM172.98,115.01l-49.25,49.24c-2.8,2.81-6.49,4.21-10.16,4.21s-7.35-1.4-10.16-4.21l-24.47-24.46c-5.61-5.61-5.61-14.7,0-20.32,5.61-5.61,14.7-5.61,20.31,0l14.31,14.33,39.09-39.1c5.61-5.61,14.7-5.61,20.31,0,5.61,5.61,5.61,14.7,0,20.31Z"
      />
      <path
        class="delivery-complete-2"
        d="M127.14,254.28C57.04,254.28,0,197.25,0,127.14S57.04,0,127.14,0s127.15,57.03,127.15,127.14-57.04,127.14-127.15,127.14ZM127.14,14.34c-62.2,0-112.8,50.6-112.8,112.8s50.6,112.8,112.8,112.8,112.81-50.6,112.81-112.8S189.34,14.34,127.14,14.34Z"
      />
    </g>
  </g>
</svg>
