/**
 * @type {{
 *   appContext: string,
 *   userContext: string,
 *   takePhotoInprogress: boolean,
 * }}
 */
export default Object.freeze({
  get appContext() {
    return localStorage.getItem("appContext");
  },
  set appContext(value) {
    if (value == null || value == undefined) {
      localStorage.removeItem("appContext");
    } else {
      localStorage.setItem("appContext", value);
    }
  },

  get userContext() {
    return localStorage.getItem("userContext");
  },
  set userContext(value) {
    if (value == null || value == undefined) {
      localStorage.removeItem("userContext");
    } else {
      localStorage.setItem("userContext", value);
    }
  },

  /**
   * メモリ不足によるクラッシュ判定のための写真撮影中フラグ
   * @returns {boolean} 写真撮影中か否か
   */
  get takePhotoInprogress() {
    return localStorage.getItem("takePhotoInprogress") === "true";
  },
  /**
   * メモリ不足によるクラッシュ判定のための写真撮影中フラグを設定
   * @param {boolean} takePhotoInprogress 写真撮影中か否か
   */
  set takePhotoInprogress(takePhotoInprogress) {
    if (takePhotoInprogress) {
      localStorage.setItem("takePhotoInprogress", "true");
    } else {
      localStorage.removeItem("takePhotoInprogress");
    }
  },
});
