<script>
  import Tooltip, { Content, Wrapper } from "@smui/tooltip";

  import AbsenceNotificationDialog from "~/pages/Update/NonDeliveryActions/AbsenceNotificationDialog.svelte";
  import ConfirmReasonDialog from "~/pages/Update/NonDeliveryActions/ConfirmReasonDialog.svelte";

  /** 配達不可の場合の理由区分 @type {number} */
  export let extraEventType;

  /** @type {import("~/libs/constants").PaymentNotPossibleDetectionTypes} 代引き金額支払不可の検知タイプ*/
  export let paymentNotPossibleType;

  /** @type {import("~/libs/constants").acceptDeniedReasonTypes} 受取拒否の理由区分 */
  export let acceptDeniedReasonType;

  /** @type {number} 代引き金額 */
  export let cashOnDeliveryAmount;

  /** @type {number} 紛失した荷物の個数 */
  export let numberOfLost;

  /** @type {boolean} 紛失登録を行なう場合にtrue */
  export let lostRegistrationFlag;

  /** @type {boolean} 紛失調査中として登録する場合にtrue */
  export let searchingForPackageFlag;

  /** @type {string} 部屋ごとのナレッジ入力欄にバインドされる内容 */
  export let editableByAddressKnowledge;

  /** @type {object} 変更前の部屋単位ナレッジ(更新有無チェック用) */
  export let changeBeforeByAddressKnowledge;

  /** @type {string} 近隣ごとのナレッジ入力欄にバインドされる内容 */
  export let editableNeighborhoodKnowledge;

  /** @type {object} 変更前の近隣ナレッジ(更新有無チェック用) */
  export let changeBeforeNeighborhoodKnowledge;

  /** 配達不可の理由を確認・選択するダイアログ @type {ConfirmReasonDialog} */
  let confirmReasonDialog;

  /** 不在通知を送信するためのダイアログ @type {AbsenceNotificationDialog} */
  let absentNotificationDialog;
</script>

<p class="caption">
  配達できない場合は以下より該当する理由を選択してください。
</p>

<section class="nonDeliveryActionsArea">
  <Wrapper rich>
    <p class="actionTitles">
      再配達
      <span tabindex="0" role="button" class="material-icons">
        info_outline
      </span>
      <Tooltip>
        <Content>
          再配達する場合はこちらから理由を選択してください。<br />
          配送センターのカゴ車に荷物を戻し、持ち戻り登録を行ってください。
        </Content>
      </Tooltip>
    </p>
  </Wrapper>
  <div class="buttonRow">
    <button
      on:click={() => {
        absentNotificationDialog.openDialog();
      }}
    >
      <span class="material-icons">person_off</span>
      <span class="nonDeriveryItem">不在・置配不可</span>
    </button>
    {#if Number.isInteger(cashOnDeliveryAmount)}
      <button
        on:click={() => {
          confirmReasonDialog.openDialog("代引支払不可");
        }}
      >
        <span class="material-icons">currency_yen</span>
        <span class="nonDeriveryItem">代引支払不可</span>
      </button>
    {/if}
    <button
      on:click={() => {
        confirmReasonDialog.openDialog("本日配達不可");
      }}
    >
      <span class="material-icons">report</span>
      <span class="nonDeriveryItem">本日配達不可</span>
    </button>
  </div>
  <Wrapper rich>
    <p class="actionTitles">
      返品
      <span tabindex="0" role="button" class="material-icons">
        info_outline
      </span>
      <Tooltip>
        <Content>
          返品用のカゴ車に入れ、返品登録をしてください。<br />
          再配達を行う荷物とは区別してください。
        </Content></Tooltip
      >
    </p>
  </Wrapper>
  <div class="buttonRow">
    <button
      on:click={() => {
        confirmReasonDialog.openDialog("住所不明");
      }}
    >
      <span class="material-icons">location_off</span>
      <span class="nonDeriveryItem">住所不明</span>
    </button>
    <button
      on:click={() => {
        confirmReasonDialog.openDialog("受取拒否");
      }}
    >
      <span class="material-icons">front_hand</span>
      <span class="nonDeriveryItem">受取拒否</span>
    </button>
    <button
      on:click={() => {
        confirmReasonDialog.openDialog("損壊");
      }}
    >
      <span class="material-icons"> broken_image </span>
      <span class="nonDeriveryItem">荷物の損壊</span>
    </button>
  </div>
</section>

<!-- ダイアログ -->
<div class="dialogs">
  <ConfirmReasonDialog
    bind:this={confirmReasonDialog}
    bind:extraEventType
    bind:paymentNotPossibleType
    bind:acceptDeniedReasonType
    bind:numberOfLost
    bind:lostRegistrationFlag
    bind:searchingForPackageFlag
    bind:editableByAddressKnowledge
    bind:changeBeforeByAddressKnowledge
    bind:editableNeighborhoodKnowledge
    bind:changeBeforeNeighborhoodKnowledge
  />
  <AbsenceNotificationDialog
    bind:this={absentNotificationDialog}
    bind:extraEventType
    bind:editableByAddressKnowledge
    bind:changeBeforeByAddressKnowledge
    bind:editableNeighborhoodKnowledge
    bind:changeBeforeNeighborhoodKnowledge
    resendFlag={false}
  />
</div>
<div class="lossRegisterArea">
  <button
    on:click={() => {
      confirmReasonDialog.openDialog("紛失");
    }}>荷物を紛失した場合はこちら</button
  >
</div>

<style lang="scss">
  .nonDeliveryActionsArea {
    background-color: #e6e6e6;
    border-radius: 10px;
    padding: 16px 16px 12px;
    margin-top: 8px;

    .actionTitles {
      margin: 0 0 6px 6px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 2px;

      .material-icons {
        font-size: 17px;
        color: #666;
      }
    }
  }

  .nonDeriveryItem {
    font-size: 13px;
    color: #333;
  }

  .caption {
    font-size: 14px;
    text-align: left;
    line-height: 1.2;
  }

  .buttonRow {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 10px;

    button {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      flex-grow: 1;
      width: 90px;
      height: 76px;
      color: #333;
      font-size: 14px;
      background-color: #fff;
      border: none;
      border-radius: 8px;
      padding: 0;

      span {
        line-height: 1;
      }

      .material-icons {
        font-size: 28px;
      }
    }
  }

  .dialogs {
    :global(.confirmDialog .mdc-dialog__content) {
      text-align: left;
      font-size: 15px;
    }
  }

  .lossRegisterArea {
    text-align: right;
    margin: 4px 0 80px;

    button {
      padding: 0;
      color: #333;
      font-size: 14px;
      background: none;
      border: none;
      text-decoration: underline;
    }
  }
</style>
