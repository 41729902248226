import { addMinutes, format as formatDate, parse as parseDate } from "date-fns";

/** JST (UTC+9) に対応する Date.prototype.getTimezoneOffset() の値 */
const JST_TIMEZONE_OFFSET = -(9 * 60);

/**
 * 日付文字列を指定の書式でフォーマットする。
 * @param {string} date 日付文字列
 * @param {string} formatStr date-fnsのフォーマット書式
 * @param {import("date-fns").FormatOptions} [options] date-fnsのフォーマットオプション
 * @returns {string} フォーマット済の日付文字列
 */
export function formatStringDate(date, formatStr, options) {
  /** @type {Date} */
  let parsedDate;
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm:ss", new Date());
  } else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd HH:mm", new Date());
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    parsedDate = parseDate(date, "yyyy-MM-dd", new Date());
  }
  return formatDate(parsedDate ?? date, formatStr, options);
}

/**
 * 日本標準時での現在時刻を取得する。
 * @returns {Date} 現在時刻（日本標準時）
 */
export function getCurrentDateTimeOnJst() {
  return getJstDate(new Date());
}

/**
 * Dateオブジェクトの日時をJST相当の時間に補正して返す。
 * @param {Date} date
 * @returns {Date}
 */
export function getJstDate(date) {
  const timezoneOffsetGap = date.getTimezoneOffset() - JST_TIMEZONE_OFFSET;
  return addMinutes(date, timezoneOffsetGap);
}

/**
 * GCを促進するためGoogleマップのiframeに適用するuse:action。
 * iframeがアンマウントされる際にsrc属性を削除する。
 * @param {HTMLIFrameElement} iframeNode
 * @returns {{
 * 	destroy: () => void
 * }}
 */
export function disposeIframe(iframeNode) {
  return {
    destroy() {
      iframeNode.removeAttribute("src");
    },
  };
}
