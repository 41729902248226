import { format as formatDate } from "date-fns";
import ky from "ky";
import { UAParser } from "ua-parser-js";

import appManager from "~/libs/appManager";
import backendApiMocker from "~/libs/backendApiMocker";
import geolocator from "~/libs/geolocator";
import iosNativeApp from "~/libs/iosNativeApp";
import Lock from "~/libs/lock";

/**
 * @typedef {{
 *   data: *,
 *   error?: ErrorResponse,
 * }} JsonResponse
 *
 * @typedef {{
 *   title: string,
 *   message: string,
 *   details: {[key: string]: object},
 * }} ErrorResponse
 *
 * @typedef {{
 *   username: string,
 *   roles: Array<string>,
 *   accessToken: string,
 *   refreshToken: string,
 *   tokenType: string,
 *   expiresIn: number,
 *   refreshExpiresIn: number,
 *   displayName: string,
 *   companyId: number,
 *   companyName: string,
 *   emailAddress: string,
 *   switchableRoles: Array<string>,
 *   switchableCompanies: Array<import("~/libs/commonTypes").Company>,
 * }} LoginResponse
 *
 * @typedef {{
 *   status: 0 | 1 | 2 | 3 | 4,
 *   customerId: number,
 *   customerName: string,
 *   signatureRequred: boolean,
 *   deliveryDays: number,
 *   address: string,
 *   correctedReceiverAddress: string,
 *   receiverName: string,
 *   desiredDate: string,
 *   desiredTime: string,
 *   numberOfPackages: number
 *   relayLocationId: number,
 *   inTransitAt: string,
 *   heldInDepotAt: string,
 *   outForDeliveryAt: string,
 *   inTransitLocation: number,
 *   heldInDepotLocation: number,
 *   outForDeliveryLocation: number,
 *   supportTheftInsurance: boolean,
 *   supportAutolockUnlocking: boolean,
 *   cashOnDeliveryAmount: number,
 *   packageDropPlace: number,
 *   damaged: boolean,
 *   extraEvent: Array<import("~/libs/commonTypes").ExtraEvent>,
 *   numberOfDeliveryAttempts: number,
 *   redeliveryContext?: RedeliveryContext,
 *   specifiedPickupDatetime?: {desiredRedeliveryDatetime: {date: string, timeFrame: string}, availablePickupDatetime: Array<{date: string, timeFrame: string}>},
 *   returnStatus: 0 | 1 | 2 | 3,
 *   returnReason: 0 | 1 | 2 | 3 | 4 | 5 | 6,
 *   requestingForReturnAt: string,
 *   waitingForReturnAt: string,
 *   waitingForReturnLocation: number,
 *   returningAt: string,
 *   returnedAt: string,
 *   lost: boolean,
 * }} QrScanResponse
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 * }[]} AddressKnowledgeRequest
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 *   latitude: number,
 *   longitude: number,
 * }[]} AddressKnowledgeResponse
 *
 * @typedef {{
 *   byAddress: RegisterByAddressKnowledgeRequest,
 *   neighborhood: RegisterNeighborhoodKnowledgeRequest,
 * }} RegisterKnowledgeRequest
 *
 * @typedef {{
 *   address: string,
 *   memo: string,
 *   updatedBy?: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} RegisterByAddressKnowledgeRequest
 *
 * @typedef {{
 *   latitude: number,
 *   longitude: number,
 *   memo: string,
 *   updatedBy?: string,
 *   updatedAt: string,
 *   address?: string,
 * }} RegisterNeighborhoodKnowledgeRequest
 *
 * @typedef {{
 *   byAddress: UpdateByAddressKnowledgeRequest,
 *   neighborhood: UpdateNeighborhoodKnowledgeRequest,
 * }} UpdateKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy?: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} UpdateByAddressKnowledgeRequest
 *
 * @typedef {{
 *   id: number,
 *   memo: string,
 *   updatedBy?: string,
 *   updatedAt: string,
 *   address?: string,
 * }} UpdateNeighborhoodKnowledgeRequest
 *
 * @typedef {Array<SearchKnowledgeRequest>} SearchKnowledgeRequests
 *
 * @typedef {{
 *   trackingNumber: string,
 *   address: string,
 *   radius: number,
 *   latitude: number,
 *   longitude: number,
 * }} SearchKnowledgeRequest
 *
 * @typedef {{
 *   searchKnowledgeResponses: Array<SearchKnowledgeResponse>,
 * }} SearchKnowledgeResponses
 *
 * @typedef {{
 *   trackingNumber: string,
 *   byAddress: ByAddressKnowledge,
 *   neighborhoods: Array<NeighborhoodKnowledge>,
 * }} SearchKnowledgeResponse
 *
 * @typedef {{
 *   id?: number,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   receiverName: string,
 * }} ByAddressKnowledge
 *
 * @typedef {{
 *   id?: number,
 *   sameAddress?: boolean,
 *   memo: string,
 *   updatedBy: string,
 *   updatedAt: string,
 *   address: string,
 * }} NeighborhoodKnowledge
 *
 * @typedef {{
 *   bundleId: string,
 *   deviceToken: string,
 * }} ApnsRequest
 *
 * @typedef {{
 *   success: Array<import("~/libs/commonTypes").Shipment>,
 *   updateFailed: Array<string>,
 * }} UpdateShipmentStatusResponse
 *
 * @typedef {{
 *   redeliveryDatetimeSpecMethod?: number,
 *   timeFramePreset?: Array<string>,
 *   redeliveryUnavailability?: Array<DateAndTimeFrame>,
 *   adjustedRedeliveryDatetime?: DateAndTimeFrame,
 *   notificationResend?: boolean,
 * }} RedeliveryContext
 *
 * @typedef {{
 *   date: string,
 *   timeFrame: string,
 * }} DateAndTimeFrame
 *
 * @typedef {{
 *   shippingReceiptUnitId: number,
 *   companyId: number,
 *   receiptLocationId: number,
 *   toReceiveOn: string,
 *   sequentialNumber: number,
 *   createdAt: string,
 *   numberOfShipments: number,
 *   numberOfPackages: number,
 *   numberOfPackagesByLocation: {locationId: number, numberOfPackages: number}[],
 * }} ShippingReceiptUnit
 *
 * @typedef {{
 *   receiptAt: string,
 *   receiptLocationId: number,
 *   cubicSize: number,
 * }} ReceivedShippingReceiptUnitRequest
 *
 * @typedef {{
 *   locationId: number,
 *   trackingNumbers: Array<{trackingNumber: string, numberOfPackages: number}>,
 * }} ReceivedShippingReceiptUnit
 *
 * @typedef {{
 *   driverType: 0 | 1, // ドライバー区分（0: 幹線輸送, 1: 宅配）
 *   results?: DeliveryRecordOfDriver | DeliveryRecordOfCoreDelivery
 *   location?: {latitude: number, longitude: number},
 * }} OperationState
 *
 * @typedef {{
 *   version : number,                          // バージョン
 *   locationSourceIdList?: Array<number>,       // 荷物を持ち出した配送センターidのリスト
 *   undeliveredList?: Array<PackageInfo>,       // 未配達荷物情報のリスト
 *   deliveredList?: Array<PackageInfo>,         // 配達完了荷物情報のリスト
 *   undeliverableList?: Array<PackageInfo>,     // 配達不可荷物情報のリスト
 *   totalDistance?: number,                     // 移動距離（単位：m）
 *   deliveryRecords?: Array<import("~/libs/commonTypes").DeliveryRecord>, // 配送記録のリスト
 *   updatedAt: string,                         // 更新日時
 * }} DeliveryRecordOfDriver  // ドライバー区分が「宅配」の場合の配達実績
 *
 * @typedef {{
 *   trackingNumber: string,
 *   location: {latitude: number, longitude: number},
 *   address: string,
 * }} PackageInfo
 *
 * @typedef {{
 *   version : number,                                // バージョン
 *   inTransitDeliveryList: Array<inTransitDelivery>, // 輸送中の荷物のリスト
 *   updatedAt: string,                               // 更新日時
 * }} DeliveryRecordOfCoreDelivery  // ドライバー区分が「幹線輸送」の場合の配達実績
 *
 * @typedef {{
 *   transportSourceId: number,                   // 荷受けした拠点のid
 *   deliveryInfoList: Array<{                    // 荷受けした拠点毎の配送情報のリスト
 *     transportDestivationId: number,            // 中継配送センター（配送先）のid
 *     trackingNumberList: Array<string>,         // 中継配送センター（配送先）毎の送り状番号のリスト
 *   }>
 * }} inTransitDelivery  // 輸送中の荷物情報
 *
 * @typedef {{
 *   driverType: 0 | 1, // ドライバー区分（0: 幹線輸送, 1: 宅配）
 *   startAt: string,
 * }} driverActivityStartRequest
 *
 * @typedef {{
 *   driverType: 0 | 1, // ドライバー区分（0: 幹線輸送, 1: 宅配）
 *   endAt: string,
 * }} driverActivityEndRequest
 */

/** リクエスト・操作を特定するIDのprefix **/
const operationIdPrefix = `${formatDate(new Date(), "MMddHHmm")}-${(
  import.meta.env.VITE_COMMIT_HASH || "NA"
).substring(0, 7)}-${(() => {
  const parsedUA = UAParser(navigator.userAgent);
  return `${parsedUA.os?.name}_${parsedUA.os?.version}/${parsedUA.browser?.name}_${parsedUA.browser?.version}`.replace(
    / /g,
    "",
  );
})()}-`;

/** @type {number} リクエスト・操作を特定するIDの連番 */
let operationSequenceNumber = 0;

/** @type {import("ky").KyInstance} */
let kyInstance;

/** @type {Lock} 認証トークン更新中のロック */
let tokenUpdatelock = new Lock();

const backendApi = {
  initialize: (
    /** @type {import("~/libs/commonTypes").UserContext} */ userContext,
  ) => {
    kyInstance = createInstance(userContext);
  },

  /**
   * ログイン
   * @param {{username: string, password: string}} data
   * @param {boolean} [forTokenRefresh] トークンリフレッシュ用の呼び出しの場合にtrueを指定
   * @returns {Promise<LoginResponse>}
   */
  async login(data, forTokenRefresh = false) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("login", {
        json: data,
        headers: { "x-for-token-refresh": forTokenRefresh ? "true" : "" },
      })
      .json();
    console.debug("ログインAPI呼び出し完了");
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * ログイン（ロール切替え）
   * @param {{username: string, password: string}} data
   * @param {string} role
   * @returns {Promise<LoginResponse>}
   */
  async loginToSwitchRole(data, role) {
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("login", {
        headers: { "X-Request-Role": role },
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 初期パスワード変更
   * @param {{userName: string, oldPassword: string, newPassword: string}} data
   */
  async changeInitialPassword(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/reset-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット依頼
   * @param {{userName: string, emailAddress: string}} data
   */
  async reqeustPasswordReset(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/request-reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワードリセット依頼
   * @param {{userName: string, pin: string, newPassword: string}} data
   */
  async passwordReset(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/password/reset", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * パスワード変更
   * @param {{oldPassword: string, newPassword: string}} data
   */
  async changePassword(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("users/change-password", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配達リスト取得
   * @returns {Promise<Array<import("~/libs/commonTypes").Shipment>>}
   */
  async getDeliveryList() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("shipments/driver/list").json();
    console.debug("配達リスト取得API呼び出し完了");
    assertNotErrorResponse(response);
    return response.data ?? []; // リストが空の場合にBEがdataプロパティのない空JSONを返すため強制変換
  },

  /**
   * 配送情報取得(1件)
   * @param {string} trackingNumber
   * @returns {Promise<import("~/libs/commonTypes").Shipment>}
   */
  async getShipmentInfo(trackingNumber) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get(`shipments/${trackingNumber}`).json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送ステータスの更新
   * @param {object} data // TODO: 型を定義する
   * @param {number} [requestedTimeStamp] APIリクエスト時のタイムスタンプ
   * @returns {Promise<UpdateShipmentStatusResponse>}
   */
  async updateShipmentStatus(data, requestedTimeStamp) {
    assertNotOffline();
    // 後続で配達実績を同期するため現在位置の取得をリクエストしておく
    if (requestedTimeStamp) {
      geolocator.requestCurrentPosition(false, requestedTimeStamp);
    }
    let requestOptions;
    if (data instanceof FormData) {
      requestOptions = { body: data };
    } else {
      requestOptions = { json: data };
    }

    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipments/status-update", requestOptions)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * スキャン時配送情報取得
   * @param {string} trackingNumber
   * @param {import("~/libs/constants").QrScanModes} mode
   * @returns {Promise<QrScanResponse>}
   */
  async getShipmentInfoByQrScan(trackingNumber, mode) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get(`shipments/${trackingNumber}/qrscan?mode=${mode}`)
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配送センター一覧の取得
   * @returns {Promise<Array<import("~/libs/commonTypes").DepotLocationWithPrefecture>>}
   */
  async getDepotLocations() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("locations").json();
    console.debug("配送センター一覧の取得API呼び出し完了");
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   *  Web Push用の公開鍵取得
   * @returns {Promise<{publicKey: string}>}
   */
  async getWebPushPublicKey() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("webpush/public-key").json();
    assertNotErrorResponse(response);
    return response.data.publicKey;
  },

  /**
   * Web Pushのサブスクリプション登録
   * @param {{endpoint: string, expiration_time: number, keys: {p256dh: string, auth: string}}} data
   */
  async registerWebPushSubscription(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("webpush/subscription", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 配達ナレッジ登録
   * @param {RegisterKnowledgeRequest} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async registerShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジ更新
   * @param {UpdateKnowledgeRequest} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async updateShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/update", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 配達ナレッジ検索
   * @param {SearchKnowledgeRequests} data
   * @returns {Promise<object>} // TODO: 型を定義
   */
  async searchShippingNnowledge(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("shipping-knowledge/search", { json: data })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * APNsのデバイストークン登録
   * @param {ApnsRequest} data
   */
  async registerApnsDeviceToken(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.post("apns", { json: data }).json();
    assertNotErrorResponse(response);
  },

  /**
   * APNsのデバイストークン削除
   * @param {ApnsRequest} data
   */
  async deleteApnsDeviceToken(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("apns/delete", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 荷受け情報一覧の取得
   * @returns {Promise<Array<ShippingReceiptUnit>>}
   */
  async getShippingReceiptUnitList() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("shipping-receipt-unit").json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 一括荷受け登録
   * @param {ReceivedShippingReceiptUnitRequest} data
   * @param {number} shippingReceiptUnitId
   * @returns {Promise<Array<ReceivedShippingReceiptUnit>>}
   */
  async registReceivedShippingReceiptUnit(data, shippingReceiptUnitId) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post(`shipping-receipt-unit/${shippingReceiptUnitId}/receive`, {
        json: data,
      })
      .json();
    assertNotErrorResponse(response);
    return response.data;
  },

  /**
   * 会社一覧(EC事業者のみ)の取得
   * @returns {Promise<Array<import("~/libs/commonTypes").Company>>}
   */
  async getEcCompanies() {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance.get("companies/ec").json();
    console.debug("会社一覧(EC事業者のみ)の取得API呼び出し完了");
    return response.data;
  },

  /**
   * ドライバー稼働状況（配達実績/現在地）の同期
   * @param {OperationState} data
   */
  async syncOperationState(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("driver-activity/sync", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 作業引継データの取得
   * @param {string} takeoverUri 作業引継URI
   * @returns {Promise<object>}
   */
  async getWorkTakeover(takeoverUri) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .get("work-takeover/" + takeoverUri)
      .json();
    assertNotErrorResponse(response);
    return response?.data;
  },

  /**
   * 作業引継データの登録・更新
   * @param {import("~/libs/constants").WorkTakeoverType} workType 引継作業種別
   * @param {object} takeoverData 作業引継データ
   * @returns {Promise<string>} 作業引継のためのURI
   */
  async postWorkTakeover(workType, takeoverData) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("work-takeover", {
        json: { workType, takeoverData },
      })
      .json();
    assertNotErrorResponse(response);
    return response?.data;
  },

  /**
   * 業務の開始
   * @param {driverActivityStartRequest} data
   */
  async beginWork(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("driver-activity/start", { json: data })
      .json();
    assertNotErrorResponse(response);
  },

  /**
   * 業務の終了
   * @param {driverActivityEndRequest} data
   */
  async finishWork(data) {
    assertNotOffline();
    /** @type {JsonResponse} */
    const response = await kyInstance
      .post("driver-activity/end", { json: data })
      .json();
    assertNotErrorResponse(response);
  },
};

export default backendApi;

/**
 * オフライン時に発生する例外
 */
export class OfflineException extends Error {
  constructor() {
    super("Offline state");
    this.name = "OfflineException";
  }
}

/**
 * バックエンドからerrorプロパティを持つレスポンスを受け取った場合に発生する例外
 */
export class ErrorResponseException extends Error {
  errorResponse;

  /**
   * @param {ErrorResponse} errorResponse
   */
  constructor(errorResponse) {
    super("Receive error response");
    this.name = "ErrorResponseException";
    this.errorResponse = errorResponse;
  }
}

/**
 * kyのインスタンスを生成する。
 * @param {import("~/libs/commonTypes").UserContext} [userContext]
 * @returns {import("ky").KyInstance}
 */
function createInstance(userContext) {
  /** @type {import("ky").Options} */
  const options = {
    prefixUrl: import.meta.env.VITE_API_SERVER_URL,
    timeout: 20000,
    retry: 1,
    parseJson: (text) => (text !== "" ? JSON.parse(text) : null),
    hooks: {
      beforeRequest: [
        async (request, options) => {
          // リクエスト・操作を特定するIDを設定
          request.headers.set(
            "X-Operation-Id",
            operationIdPrefix +
              iosNativeApp.getCurrentVersion().replace(/(.+)/, "$1-") +
              ++operationSequenceNumber,
          );

          console.debug(
            `beforeRequest[0]開始, ${request.url}, ${request.headers.get("X-Operation-Id")}`,
          );

          const forTokenRefreshRequest =
            "true" === request.headers.get("x-for-token-refresh");

          if (
            !forTokenRefreshRequest &&
            userContext?.loginUser?.accessToken &&
            userContext?.loginUser?.refreshToken &&
            !request.headers.get("X-Request-Role")
          ) {
            const currentTime = Date.now();
            const accessTokenRefreshThreshold =
              userContext.loginUser.loginTime +
              Math.max(
                Math.floor(userContext.loginUser.expiresIn / 2),
                60 * 60, // min 1 hour
              ) *
                1000;
            if (currentTime >= accessTokenRefreshThreshold) {
              await tokenUpdatelock.acquire();
              console.debug(
                `ロック取得, ${request.url}, ${request.headers.get("X-Operation-Id")}`,
              );

              try {
                // 二重にトークン更新をしないように、ロックが取れたら改めてトークンの有効期限を確認
                if (currentTime >= accessTokenRefreshThreshold) {
                  const accessTokenExpires =
                    userContext.loginUser.loginTime +
                    userContext.loginUser.expiresIn * 1000;
                  console.log(
                    `アクセストークンの更新が必要：${new Date(
                      currentTime,
                    ).toLocaleString()} ≧ ${new Date(
                      accessTokenRefreshThreshold,
                    ).toLocaleString()} (${new Date(
                      accessTokenExpires,
                    ).toLocaleString()}), ${request.url}, ${request.headers.get("X-Operation-Id")}`,
                  );

                  // 新しいアクセストークンを取得（ログインAPIの呼出）
                  const currentAccessToken = userContext.loginUser.accessToken;
                  const currentRefreshToken =
                    userContext.loginUser.refreshToken;
                  try {
                    // リフレッシュトークンもどきの方が有効期限が長い場合は一時的に差し替え
                    if (
                      userContext.loginUser.refreshExpires > accessTokenExpires
                    ) {
                      userContext.loginUser.accessToken = currentRefreshToken;
                      console.debug(
                        `一時的にトークン入れ替え完了, ${request.url}, ${request.headers.get("X-Operation-Id")}`,
                      );
                    }

                    const loginResponse = await backendApi.login(
                      {
                        username: userContext.loginUser.username,
                        password: "N/A",
                      },
                      true,
                    );
                    userContext.loginUser = {
                      username: loginResponse.username,
                      roles: loginResponse.roles,
                      accessToken: loginResponse.accessToken,
                      refreshToken: currentRefreshToken,
                      expiresIn: loginResponse.expiresIn,
                      refreshExpires:
                        loginResponse.refreshExpiresIn > 0
                          ? currentTime + loginResponse.refreshExpiresIn * 1000
                          : undefined,
                      loginTime: currentTime,
                      displayName: loginResponse.displayName,
                      companyId: loginResponse.companyId,
                      companyName: loginResponse.companyName,
                      emailAddress: loginResponse.emailAddress,
                      switchableRoles: loginResponse.switchableRoles,
                      switchableCompanies: loginResponse.switchableCompanies,
                    };
                    userContext.store();
                  } catch (error) {
                    console.error(error); // use non-logger explicitly
                    if (userContext.loginUser) {
                      if (
                        userContext.loginUser.accessToken ===
                        currentRefreshToken
                      ) {
                        userContext.loginUser.accessToken = currentAccessToken;
                        delete userContext.loginUser.refreshToken;
                      }
                    }
                  }
                }
                // 本来のリクエストに取得したアクセストークンを設定
                request.headers.set(
                  "Authorization",
                  "Bearer " + userContext.loginUser.accessToken,
                );
              } finally {
                tokenUpdatelock.release();
                console.debug(
                  `ロック解放, ${request.url}, ${request.headers.get("X-Operation-Id")}`,
                );
              }
            }
          }

          // 認証トークンの設定
          if (!request.headers.get("X-Request-Role")) {
            if (userContext?.loginUser?.accessToken) {
              request.headers.set(
                "Authorization",
                "Bearer " + userContext.loginUser.accessToken,
              );
            }
          } else {
            if (userContext?.loginUser?.refreshToken) {
              request.headers.set(
                "Authorization",
                "Bearer " + userContext.loginUser.refreshToken,
              );
            }
          }

          // モック用のリクエスト書き換え処理
          _REMOVABLE_MOCK_: {
            if (import.meta.env.VITE_USE_MOCK_API) {
              backendApiMocker.prepareRequest(request, options);
            }
            break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
          }

          console.debug(
            `beforeRequest[0]終了, ${request.url}, ${request.headers.get("X-Operation-Id")}`,
          );
        },
        (request) => {
          // 掃除
          request.headers.delete("x-for-token-refresh");
        },
      ],

      afterResponse: [
        (request, options, response) => {
          const appRequiredTime = Number.parseInt(
            response.headers.get("x-app-required-time"),
          );
          if (Number.isInteger(appRequiredTime)) {
            appManager.offerUpdateIfNeeded(appRequiredTime);
          }
        },
      ],

      beforeError: [
        async (error) => {
          if (
            error.response?.headers
              ?.get("Content-Type")
              ?.match(/^application\/json(?:;|$)/)
          ) {
            try {
              /** @type {ErrorResponse} */
              const errorResponse = (await error.response.json())?.error;
              if (errorResponse) {
                error["errorResponse"] = errorResponse;
              }
            } catch (error) {
              console.error(error); // use non-logger explicitly
            }
          }
          return error;
        },
      ],

      beforeRetry: [
        async ({ request, error, retryCount }) => {
          console.log(`retry request (${retryCount}): ${request.url}`, error);
        },
      ],
    },
  };

  // モック用のレスポンス書換処理
  _REMOVABLE_MOCK_: {
    if (import.meta.env.VITE_USE_MOCK_API) {
      if (!options.hooks.afterResponse) {
        options.hooks.afterResponse = [];
      }
      options.hooks.afterResponse.push(backendApiMocker.rewriteResponse);
    }
    break _REMOVABLE_MOCK_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
  }

  return ky.extend(options);
}

/**
 * オフライン状態でないことをアサートする。
 */
function assertNotOffline() {
  if (!navigator.onLine) {
    throw new OfflineException();
  }
}

/**
 * errorプロパティを持つレスポンスでないことをアサートする。
 * @param {JsonResponse} response
 */
function assertNotErrorResponse(response) {
  if (response.error) {
    throw new ErrorResponseException(response.error);
  }
}
