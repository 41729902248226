<script>
  import { createEventDispatcher, getContext, onMount } from "svelte";

  import image1 from "~/assets/images/help/offlineMode1.png";
  import image2 from "~/assets/images/help/offlineMode2.png";
  import image3 from "~/assets/images/help/offlineMode3.png";
  import image4 from "~/assets/images/help/offlineMode4.png";
  import image5 from "~/assets/images/help/offlineMode5.png";
  import { CONTEXT_KEY_APP } from "~/libs/constants";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  let contentsArea;
  let currentPage = 1;
  let dispatch = createEventDispatcher();

  onMount(() => {
    contentsArea.style.marginLeft = "+400%";
  });

  function previousPage() {
    if (currentPage > 1) {
      contentsArea.style.marginLeft = String((currentPage - 4) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage--;
    }
  }

  function nextPage() {
    if (currentPage < 5) {
      contentsArea.style.marginLeft = String((currentPage - 2) * -200) + "%";
      contentsArea.style.transition = ".2s";
      currentPage++;
    }
  }

  function cancel() {
    dispatch("cancel");
  }

  function confirm() {
    dispatch("confirm");
  }
</script>

<!-- タイトルエリア -->
<div class="titleArea">
  <p>オフラインモードについて</p>
</div>
<!-- コンテンツ表示エリア -->
<div class="helpContentsArea" bind:this={contentsArea}>
  <div class="helpContent">
    <img class="helpContentImage" src={image1} alt="offline" />
    <div class="messaggeArea">
      <p>
        オフラインモードは、通信状況が<br />
        悪い時やサーバーでの障害発生時<br />
        にも、<span class="strong"
          >配達完了手続きを行うこと<br />
          ができるモード</span
        >です。
      </p>
    </div>
  </div>
  <div class="helpContent">
    <img class="helpContentImage" src={image2} alt="package" />
    <div class="messaggeArea">
      <p>
        アプリ上で宛名や住所(地図)表示<br />
        はできないため、<span class="strong"
          >送り状の内容<br />
          をもとに移動し、受け渡し前に<br />
          荷物をスキャン</span
        >して配達完了<br />
        手続きを行ってください。
      </p>
    </div>
  </div>
  <div class="helpContent">
    <img class="helpContentImage2" src={image3} alt="delivered" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage2" src={image4} alt="send request" />
  </div>
  <div class="helpContent">
    <img class="helpContentImage3" src={image5} alt="out for delivery" />
    <div class="messaggeArea">
      <p>
        オフラインモード利用中は<span class="strong"
          >アプリ<br />
          での操作はせずに荷物を持ち出し</span
        ><br />
        てください。また、持ち戻りの際<br />
        も<span class="strong"
          >アプリでの操作はせずに配送セ<br />
          ンターのカゴ車に戻し</span
        >てください。
      </p>
    </div>
    <div class="confirmButtonArea">
      {#if !appContext.offlineMode}
        <button class="cancelButton" on:click={cancel}>キャンセル</button>
        <button class="confirmButton" on:click={confirm}
          >オフラインモード<br />に切替</button
        >
      {:else}
        <button class="okButton" on:click={confirm}>OK</button>
      {/if}
    </div>
  </div>
  <div class="helpContent" />
</div>
<!-- ページ管理エリア -->
<div class="pageManageArea">
  <div class="pageButtonArea">
    <button class="pageButton" id="previousButton" on:click={previousPage}>
      <span class="dli-chevron-left"></span>
    </button>
  </div>
  <div class="pageStatusArea">
    <div class="pageStatus">
      <div class="page" class:currentPage={currentPage == 1}></div>
      <div class="page" class:currentPage={currentPage == 2}></div>
      <div class="page" class:currentPage={currentPage == 3}></div>
      <div class="page" class:currentPage={currentPage == 4}></div>
      <div class="page" class:currentPage={currentPage == 5}></div>
    </div>
  </div>
  <div class="pageButtonArea">
    <button class="pageButton" id="nextButton" on:click={nextPage}>
      <span class="dli-chevron-right"></span>
    </button>
  </div>
</div>

<style>
  /* タイトルエリア */
  .titleArea {
    margin: 35px 0 20px;
    text-align: center;
    line-height: 23px;
    font-size: 21px;
    font-weight: bold;
  }
  /* コンテンツ表示エリア */
  .helpContentsArea {
    position: relative;
    display: flex;
    width: 500%;
    height: 410px;
  }
  .helpContent {
    min-width: 20%;
    text-align: center;
  }
  .helpContentImage {
    margin: 45px 0 50px;
    width: 160px;
    height: 160px;
  }
  .helpContentImage2 {
    margin: 0 -20px;
    width: 340px;
    height: 420px;
    border-radius: 10px;
  }
  .helpContentImage3 {
    margin: 20px 0 30px;
    width: 150px;
    height: 150px;
  }
  .messaggeArea {
    margin: 0 auto;
    padding-left: 20px;
    width: 100%;
    max-width: 280px;
    text-align: left;
    font-size: 17px;
    line-height: 25px;
  }
  .strong {
    background: linear-gradient(transparent 50%, #83d3b8 80%);
  }
  .confirmButtonArea {
    display: flex;
    width: 100%;
    height: 20%;
    justify-content: center;
  }
  .cancelButton {
    display: inline-block;
    margin: 15px 20px 15px 0;
    width: 110px;
    height: 60px;
    font-size: 15px;
    color: #fff;
    background-color: #929292;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .confirmButton {
    display: inline-block;
    margin: 15px 0;
    width: 160px;
    height: 60px;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .okButton {
    display: inline-block;
    margin: 15px 0;
    width: 150px;
    height: 50px;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    background-color: #018786;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  /* ページ管理エリア */
  .pageManageArea {
    width: 100%;
    height: 85px;
    display: flex;
  }
  .pageButtonArea {
    position: relative;
    width: 20%;
    vertical-align: auto;
  }
  .pageButton {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border: none;
    background: transparent;
    cursor: pointer;
  }
  .pageButton :focus {
    background-color: white;
  }
  .dli-chevron-left {
    display: inline-block;
    vertical-align: middle;
    color: #018786;
    line-height: 1;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(25%) rotate(-135deg);
  }
  .dli-chevron-right {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    color: #018786;
    width: 2em;
    height: 2em;
    border: 0.4em solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateX(-25%) rotate(45deg);
  }
  .pageStatusArea {
    position: relative;
    width: 60%;
    display: flex;
  }
  .pageStatus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 20px;
    justify-content: center;
    display: flex;
  }
  .page {
    width: 12px;
    height: 12px;
    margin: 0 10px;
    border-radius: 10px;
    background-color: #d8d8d8;
  }
  .currentPage {
    background-color: #018786;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  }
</style>
